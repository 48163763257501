import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";

export const fatchUpcomming = createAsyncThunk('fatchUpcomming', async()=>{
    const response = await fetch("http://sagarvora1-001-site3.atempurl.com/api/v1/upcomming-match");
    return response.json();
}) 

export const umcommingSlice = createSlice({
    name: "Upcomming",
    initialState:{
        isLoading:false,
        data:[],
        isError:false,
        isFatched:false
    },
    extraReducers:(builder)=>{
        builder.addCase(fatchUpcomming.pending,(state,action)=>{
            state.isLoading = true;
        });
        builder.addCase(fatchUpcomming.fulfilled,(state,action)=>{
            state.isFatched=true;
            state.isLoading = false;
            state.data = action.payload;
            
        })
        builder.addCase(fatchUpcomming.rejected,(state,action)=>{
            state.isError = true;
            console.log("Error",action.payload)
        })
    }
  });

 

  export default umcommingSlice.reducer;