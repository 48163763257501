import React from "react";
import "./UmpireTour.css";
// MUI DROPDOWN
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { useState } from "react";
import { Link } from "react-router-dom";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "@mui/material/Button";
import { Outlet } from "react-router-dom";
import tennislogo from "../../assets/tennis-logo.png";
import { useEffect } from "react";
import axiosBaseURL from "../../httpCommon";

const loginAdmin = () => {
  navigate("/login");
};

const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const index = () => {
  
  const [data, setData] = useState([]);
  console.log(data, "HurrDattaaa");
  useEffect(() => {
    const bearerToken = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    axiosBaseURL
      .get(`/upcomming-match`, bearerToken)
      .then((resp) => {
        setData(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="umpire_pr">
      <div className="main_header">
        <div className="tennis_header">
          <div className="header_logo">
            <img src="" alt="" />
            <div className="tennnisHeading">
              <img src={tennislogo} alt="" />
              <h2>Umpire App</h2>
            </div>
          </div>
          <div className="header_signup">
            <Link to="/">
              {/* <button><h6>Home</h6> <HomeIcon className="homeIcon"/></button> */}
              <Button variant="outlined">Home</Button>
            </Link>
            {/* <button onClick={handleClickOpen}>Login Umpire</button> */}
            <div className="btnDiv">
              {[DropdownButton].map((DropdownType, idx) => (
                <DropdownType
                  as={ButtonGroup}
                  key={idx}
                  id={`dropdown-button-drop-${idx}`}
                  title="Log In"
                  className="btn_drpdn"
                >
                  <Dropdown.Item eventKey="1" onClick={loginAdmin}>
                    Admin
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey="4" onClick={handleClickOpen}>
                    Umpire
                  </Dropdown.Item>
                </DropdownType>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="umpireapp_listing_sidebar">
        <div className="umpApp_li_sidebar">
          <h3>Upcomming Match Details</h3>
          {
            (data.length ===0 )?(
              <div className="upCommingNodata">
              <h3>No Data</h3>
              </div>
            ):
            (
              <>
              {data.map((map) => {
                return (
                  <>
                    {console.log(map, "mapppaaa")}
                    <div className="match_list_odd">
                      <p> Match Date : {map.match_date} </p>
                      <p>
                       <span className="UpCommingTeams">{map.team_one.team_name}</span> &nbsp; vs  &nbsp; <span className="UpCommingTeams">{map.team_two.team_name}</span>
                      </p>
                      <p>Umpire Name : {map.umpire.umpire_name}</p>
                    </div>
                  </>
                );
              })}
              </>
            )
          }
        </div>
        <div className="matchlis_detailspr">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default index;
