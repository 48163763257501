import React from "react";
import "./Login.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosBaseURL from "../httpCommon";
const Login = () => {
  const navigate = useNavigate();

  const [login, IsLogin] = useState({
    email: "",
    password: "",
  });
  const [show,setShow]= useState(false)

  const handleChange = (e) => {
    const { value, name } = e.target;
    IsLogin((oldValue) => {
      return {
        ...oldValue,
        [name]: value,
      };
    });
  };

  const handleSubmit=(e)=>{
    e.preventDefault()
    console.log(login)
    IsLogin({
      email:"",
      password:""
    })

    axiosBaseURL.post('/login',login)
      .then((resp) => {
        console.log(resp,"Daaataaata")
        localStorage.setItem('tokenTennis',resp.data.authorization)
        
        if(resp.data.message === "Login successfull" ){
              navigate('/admindash/tournamentslisting')
        }else{
          alert(resp.data.error)
        }
      })
      .catch((err) => {
        alert(err)
      });
  }
  const handleToggle = ()=>{
    setShow(true)
  }
  const handleSpan=()=>{
    setShow(false)
  }
const Login = ()=>{
  
}
  return (

    <div className="tennis_Login">

      {
        !show ?    
         <div className="tennis_form">
        <form action="" onSubmit={handleSubmit}>
          <h1>Sign In</h1>
          <input
            type="text"
            placeholder="User Name or Email Address"
            onChange={handleChange}
            value={login.email}
            name="email"
          />
          <input
            type="password"
            placeholder="Password"
            onChange={handleChange}
            value={login.password}
            name="password"
          />
          <button type="submit" >Submit</button>
          
        </form>
        
        {/* <hr style={{
          width:"50%",
          textAlign:"center",
          margin:"10px auto"
        }}/> */}

         {/* <button className="SignUp_tennis"  onClick={handleToggle}>Create New Account</button> */}
      </div> :  
      
{/* <div className="tennis_Login">
<div className="tennis_form">
  <form action="" >
    <h1>SignUp</h1>
    <input 
    type="text"
    placeholder='Name'
     />
    <input
      type="text"
      placeholder="Email Address"
    />
    <input
      type="password"
      placeholder="Password"
    />
    <input
      type="password"
      placeholder="Confirm Password"
    />
    <button>Register</button>
   <p className='login_span' onClick={handleSpan}>Sign In Instead ?</p>
  </form>
</div>
</div> */}
      }

    </div>
  );
};

export default Login;
