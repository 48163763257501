import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import './CreateTeam.css'
import Avatar from '@mui/material/Avatar';
import { Link } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import axiosBaseURL from '../../../httpCommon'
const index = () => {

  const location = useLocation()  


  console.log(location.state,"location")


  const [createTeam,setCreateTeam] = useState({
    team_name:location.state.team_name,
    team_location:location.state.team_location,
    player_name_one:location.state.player_name_one,
    player_name_two:location.state.player_name_two,
  })

  const [image,setImage]=useState({
    player_one_image:"",
  });

  const [image2,setImage2]=useState({
    player_two_image:"",
  });

  const imageChnageHandler = (event)=>{
    setImage(event.target.files[0])
}
const imageChnageHandler2 = (event)=>{
  setImage2(event.target.files[0])
}



  const changeHandler = (e)=>{
    const {name,value} = e.target;  
    setCreateTeam((oldValue)=>{
      return{
        ...oldValue,
        [name]:value
      }
    })
  }
  
  const [createTeamData,setCreateTeamData]=useState([])
  
  console.log(createTeamData)
  
  const handleSubmit=()=>{
    
    // setCreateTeamData([...createTeamData,createTeam])

    const token = localStorage.getItem('tokenTennis')



    const formData = new FormData();
    formData.append('team_name',createTeam.team_name) 
    formData.append('team_location',createTeam.team_location) 
    formData.append('player_name_one',createTeam.player_name_one) 
    formData.append('player_one_image',image) 
    formData.append('player_name_two',createTeam.player_name_two) 
    formData.append('player_two_image',image2) 

    const bearerToken = {headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    }}
    
      axiosBaseURL.post(`/teams/${location.state.id}`, formData, bearerToken)
        .then((resp) => {
          // alert(resp.data.message);
          console.log(resp,"Daaataaata")
        })
        .catch((err) => {
          console.log(err);
        });
  }

  return (
    <div className="createMatch_">
      <div className="createMatch_header">
        <h3>Update Team </h3>
        <button className="creat_matchbtn">Update Team</button>
      </div>
      <div className="createMatch_fields">
        <Box
          sx={{
            width: "95%",
            maxWidth: "100%",
            padding: "15px 0",
          }}
        >
          <label>Team Name</label>
          <TextField
            fullWidth
            label=""
            id="fullWidth"
            placeholder="Name"
            name="team_name"
            value={createTeam.team_name}
            onChange={changeHandler}
          />
        </Box>
        <Box
          sx={{
            width: "95%",
            maxWidth: "100%",
            padding: "15px 0",
          }}
        >
          <label>Team Location</label>
          <TextField
            fullWidth
            label=""
            id="fullWidth"
            placeholder="Location"
            name="team_location"
            value={createTeam.team_location}
            onChange={changeHandler}
          />
        </Box>
      </div>
      <div className="PlayerDetails">
        <div className="playerDetailsWraappepr">
          <div className="player_names">
        <label htmlFor="">Player Name :</label>
        <TextField
            label=""
            id="fullWidth"
            placeholder= "Name"
            name="player_name_one"
            value={createTeam.player_name_one}
            onChange={changeHandler}
          />
        <TextField
            label=""
            id="fullWidth"
            placeholder= "Name"
            name="player_name_two"
            value={createTeam.player_name_two}
            onChange={changeHandler}
          />
  
          </div>
          <div className="player_Images">
          <label htmlFor="">Player Image :</label>
          <div className="playerImageWrappper">
              <Avatar alt="Remy Sharp" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUSu6jfCnQd_iEHZ_e7UYfekCDRm5OOkjdbQ&usqp=CAU"     sx={{ width: 34, height: 34,fontSize:15 }}/>


              <input 
              type="file" 
              name="player_one_image"
              // value={createTeam.player_one_image}
              onChange={imageChnageHandler}
               />
          </div>
          <div className="playerImageWrappper">
              <Avatar alt="Remy Sharp" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQF16fmThymqrxaO7lVS4YuBwVSvG0RXnNLnQ&usqp=CAU"     sx={{ width: 34, height: 34,fontSize:15 }}/>
              <input 
              type="file"
              name="player_two_image"
              // value={image2.player_two_image}
              onChange={imageChnageHandler2}
              />
          </div>

          </div>
      </div>
      </div>
      
      <div className="create_team_btns">
        <Link to ="/admindash/teamListing">
            <button className="crt_team_save" onClick={handleSubmit}>Update</button>
        </Link>
            <button className="crt_team_cancle">CANCLE</button>
      </div>
    </div>
  );
};

export default index;
