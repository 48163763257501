import React from 'react'
import './tournamentslisting.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Link} from 'react-router-dom'
// Mui Icons
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'; //Create New
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded'; //Edit
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'; //Delete
import { useEffect } from 'react';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import axiosBaseURL from '../../../httpCommon'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TournamentListing = () => {
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }


  

  const [open, setOpen] = React.useState(false);
  const [delData,setDelData] = React.useState()

  const [updateData,setUpdateData]= React.useState()

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (delId) => {
    setOpen(true);
    setDelData(delId)
  };

  const handleUpdate = (row)=>{
    setUpdatePop(true);
    setUpdateData(row)
  }


  const [mappedData,setMappedData]= React.useState([])

  const [updatePop,setUpdatePop] = React.useState(false)
 
  const token = localStorage.getItem('tokenTennis')
  
  useEffect(()=>{

    axiosBaseURL.get('/tournaments',{ headers: {"Authorization" : `Bearer ${token}`} })
      .then((resp) => {
        // alert(resp.data.message);
        setMappedData(resp.data.data)
        console.log(resp.data.data,"hcjvjkxcvhcxkvj")
      })
      .catch((err) => {
        console.log(err);
      });
  },[])

  const deleteHandler = (id)=>{
    const bearerToken = {headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      Authorization: `Bearer ${token}`
    }}


      axiosBaseURL.delete(`/tournaments/${id.id}`, bearerToken)
        .then((resp) => {
          // alert(resp.data.message);
          console.log(resp,"Daaataaata")

          if(resp.data.data === true){
            
            axiosBaseURL.get('/tournaments',{ headers: {"Authorization" : `Bearer ${token}`} })
              .then((resp) => {
                // alert(resp.data.message);
                console.log(resp.data.data,"Daaataaata")
                setMappedData(resp.data.data)
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        }); 
        setOpen(false)
  } 

  return (
    <div className='tournamentListing'>
       <div className='tournamentListing_header'>
            <h3>Tournament Listing</h3>
          <Link to='/admindash/tournaments'>
            <button className='trnamnet_btn'>Create New Tournament <AddCircleOutlineRoundedIcon/></button>
            </Link>
       </div>
       <div className='tournamentLi_table'>
       <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow  >
            <TableCell >Tournament Name</TableCell>
            <TableCell align="right">Match Category</TableCell>
            <TableCell align="right">Match Name</TableCell>
            <TableCell align="right">Court Type</TableCell>
            <TableCell align="right">Tournament Key</TableCell>
            <TableCell align="right">Update/Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className='tournament_tablebody' >
          {console.log(mappedData,"mappedData")}
          {mappedData.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              className='tournament_tableRow'
            >
              <TableCell component="th" scope="row">
                {row.tournament_name}
              </TableCell>
              
              <TableCell  align="right" className='matchCategory_td'>
               {row.matchCategoryData.map((e)=>{
                return (
                <>
                {e.match_category_name}
                </>)
               })}
              </TableCell>

              <TableCell align="right">Match name</TableCell>
              <TableCell align="right">{row.courtTypeData.court_type_name}</TableCell>
              <TableCell align="right">{row.tournament_code}</TableCell>
              <TableCell align="right">
              <BorderColorRoundedIcon className='tournamnet_edit_' onClick={()=>handleUpdate(row)}/>
              
               <DeleteRoundedIcon className='tournamnet_delbtns'  onClick={()=>handleClickOpen(row)}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        
    <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      > 
      <div className='team_dialogbox'>
      <h4>Delete This Data?</h4>
        <div className='diabuttonsWrapper'>
            <button className='dialogButton_yes' onClick={()=>deleteHandler(delData)}>Yes</button>
            <button className='dialogButton_no' onClick={()=>setOpen(false)}> No</button>
        </div>
        </div>
      </Dialog>

      <Dialog
        open={updatePop}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      > 
      <div className='team_dialogbox'>
      <h4>Edit This Data?</h4>
        <div className='diabuttonsWrapper'>
            <button className='EditdialogButton_yes' >
               <Link to='/admindash/updatetournament' state={updateData} >Yes</Link>
               </button>
            <button className='dialogButton_no' onClick={()=>setUpdatePop(false)}> No</button>
        </div>
        </div>
      </Dialog>
       </div>

    </div>
  )
}
export default TournamentListing;