import React from "react";
import "./Header.css";
import tennislogo from '../../assets/tennis-logo.png'
import Umpirelogin from "../umpirelogin";
// Mui Tabs
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
// Mui Dialoug box
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { headerFalse } from "../../Redux/headerSlice/index";
import { useNavigate } from "react-router-dom";
// Mui DropDown
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useEffect } from "react";
import { fatchNews } from "../../Redux/NewsApi";
import { useState } from "react";
import axios from "axios";
// Mui Icons
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

import HomeIcon from "@mui/icons-material/Home";
import LoginIcon from "@mui/icons-material/Login";
import NewspaperIcon from "@mui/icons-material/Newspaper";
// Bootstrap Drawer
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "@mui/material/Button";
import axiosBaseURL from "../../httpCommon";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Header = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [news, setNews] = useState([]);
  
  const object = {
    update: { value: 1 },
    currMatch: { value: 2 },
    upCommMatch: { value: 3 },
    prevMatch: { value: 4 },
  };
  const [objectValue, setObjectvalue] = React.useState(object.update.value);

  const Selector = useSelector((state) => state.headerSlice);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const loginAdmin = () => {
    navigate("/login");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const clickHandleTab = (para) => {
    if (para === 1) {
      setObjectvalue(object.update.value);
    }
    if (para === 2) {
      setObjectvalue(object.currMatch.value);
    }
    if (para === 3) {
      setObjectvalue(object.upCommMatch.value);
    }
    if (para === 4) {
      setObjectvalue(object.prevMatch.value);
    }
  };
  const leaveHandler = () => {
    dispatch(headerFalse(false));
    navigate("/");
  };

  const [age, setAge] = React.useState("");

  const handleDropChange = (event) => {
    setAge(event.target.value);
  };



  useEffect(()=>{
    // const baseURL = `${axiosBaseURL}/news-list';
    axiosBaseURL.get('/news-list').then((resp) => {
        // alert(resp.data.message);
        console.log(resp.data.data,"hcjvjkxcvhcxkvj")
        setNews(resp.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  },[])


  return (
    <>
      <div className="tennis_header_prContainer">
        <div className="tennis_header_pr">
          <div className="tennis_header">
            <div className="header_logo">
              <img src={tennislogo} alt="" />
              <div className="tennnisHeading">
                <h2>Tennis Scoring</h2>
              </div>
            </div>
            <div className="header_signup">
              <Link to="/">
                {/* <button><h6>Home</h6> <HomeIcon className="homeIcon"/></button> */}
                <Button variant="outlined">Home</Button>
              </Link>
              {/* <button onClick={handleClickOpen}>Login Umpire</button> */}
              <div className="btnDiv">
                {[DropdownButton].map((DropdownType, idx) => (
                  <DropdownType
                    as={ButtonGroup}
                    key={idx}
                    id={`dropdown-button-drop-${idx}`}
                    title="Log In"
                    className="btn_drpdn"
                  >
                    <Dropdown.Item eventKey="1" onClick={loginAdmin}>
                      Admin
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item eventKey="4" onClick={handleClickOpen}>
                      Umpire
                    </Dropdown.Item>
                  </DropdownType>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="pr_SubContainer">
          <div className="header_asideleft">
            <div>
              <h1>
                News <NewspaperIcon className="news_icon" />
              </h1>
            </div>
            {
              news.map((currValue,index)=>{
                return(
                  <>
           {       console.log (currValue,"currValue")}

           <div className="news_container" key={index}>
              <h4 className="newsDetails_head" >{currValue.news_title}</h4>
              <div className="newsDetails">
                <p>
                  {currValue.news_description} <br/>
                  <Link to="/newsLink">
                    <span className="readMore_link">Read More...</span>
                  </Link>
                </p>
              </div>
            </div>
                  </>
                )
               
              })
            }
          </div>
          <div className="Content_wrapper">
            {Selector.value === false ? (
              <Box className="header_tabs">
                <Tabs value={value} onChange={handleChange} centered>
                  <Link to="/">
                    {objectValue === 1 ? (
                      <Tab label="Updates" className="Header_tbs" style={{borderBottom:"3px solid black"}}
                      />
                    ) 
                    : 
                    (
                      <Tab
                        label="Updates"
                        className="Header_tbs"
                        onClick={() => clickHandleTab(1)}
                      />
                    )}
                  </Link>
                  <Link to="/currentmatches">
                    {objectValue === 2 ? (
                      <Tab label="Current Matches" className="Header_tbs"  style={{borderBottom:"3px solid black"}}/>
                    ) : (
                      <Tab
                        label="Current Matches"
                        className="Header_tbs"
                        onClick={() => clickHandleTab(2)}
                      />
                    )}
                  </Link>

                  <Link to="upcommingmatches">
                    {objectValue === 3 ? (
                      <Tab label="Upcomming Matched" className="Header_tbs"  style={{borderBottom:"3px solid black"}}/>
                    ) : (
                      <Tab
                        label="Upcomming Matched"
                        className="Header_tbs"
                        onClick={() => clickHandleTab(3)}
                      />
                    )}
                  </Link>
                  <Link to="previousmatches">
                    {objectValue === 4 ? (
                      <Tab label="Previous Matches" className="Header_tbs" style={{borderBottom:"3px solid black"}} />
                    ) : (
                      <Tab
                        label="Previous Matches"
                        className="Header_tbs"
                        onClick={() => clickHandleTab(4)}
                      />
                    )}
                  </Link>
                  {/* <Tab label="Umpire" className='Header_tbs' /> */}
                </Tabs>
              </Box>
            ) : (
              <Box className="umpire_app">
                <h3>Chair Umpaire</h3>
                <button onClick={leaveHandler}>Leave</button>
              </Box>
            )}
            <div className="outlet_Container">
              <div className="matches_">
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  aria-describedby="alert-dialog-slide-description"
                  className="login_dialog"
                >
                  <div className="Log_head">
                    {/* <Login  /> */}
                    <Umpirelogin setOpen={setOpen} />
                  </div>
                </Dialog>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
