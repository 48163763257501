import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


export const fatchNews = createAsyncThunk('fatchUpcomming', async()=>{
    const response = await fetch("http://sagarvora1-001-site3.atempurl.com/api/v1/news-list");
    return response.json();
}) 

export const newsApiSlice = createSlice({
    name: "Upcomming",
    initialState:{
        isLoading:false,
        data:[],
        isError:false
    },
    extraReducers:(builder)=>{
        builder.addCase(fatchNews.pending,(state,action)=>{
            state.isLoading = true;
       
        });
        builder.addCase(fatchNews.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.data = action.payload
        })
        builder.addCase(fatchNews.rejected,(state,action)=>{
            state.isError = true;
            console.log("Error",action.payload)
        })
    }
  });

 

  export default newsApiSlice.reducer;