import React from "react";
import "./Tournament.css";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { useState } from "react";
// Mui DropDown
import "./Tournament.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import axiosBaseURL from '../../../httpCommon'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const courtTypeData = [
  "Clay Court",
  "Hard Court",
  "Grass Court",
  "Carpet Court",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const UpdateTournament = () => {
  const location = useLocation();

  const [data, setData] = useState(location.state);

  console.log(location.state, "hellooooooo");


  console.log(data.courtTypeData.court_type_name, "aaaaaaaaaa");

  const match_name123 = data.matchData.map((e)=>{
      return e.match_name
  })

  const matchCategoryName = data.matchCategoryData.map((datas) => {
    return datas.id;
  });

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const token = localStorage.getItem("tokenTennis");

  const [courtData, setCourtData] = useState([]);
  const [matchNameUpdateapi, setMatchNameapi] = useState([]);
  
  const matchCategoryApi = matchNameUpdateapi.map((map) => {
    return map.match_name;
  });

  useEffect(() => {

    axiosBaseURL.get('/courts', {
        headers: { Authorization: `Bearer ${token}` },
        Accept: "application/json",
      })
      .then((resp) => {
        // alert(resp.data.message);
        setCourtData(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  
  useEffect(() => {

    axiosBaseURL.get('/matches', {
        headers: { Authorization: `Bearer ${token}` },
        Accept: "application/json",
      })
      .then((resp) => {
        console.log(resp, "useEffects matches");
        setMatchNameapi(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const theme = useTheme();
  const [matchName, setMatchName] = React.useState(match_name123);

  const [matchCat, setMatchCat] = React.useState(matchCategoryName);

  const [courtType, setcourtType] = React.useState(data.courtTypeData.court_type_name);

  console.log(courtType,"courtType")

  const [catData, setcatData] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMatchName(typeof value === "string" ? value.split(",") : value);
  };

  const matchCatChnage = (event) => {
    setMatchCat(event.target.value);
  };

  const courtTypeChange = (event) => {
    console.log(event.target.value,"event.target.value")
    setcourtType(event.target.value);
  };

  const [dataStore, setDataStore] = useState([]);
  const chnageHandler = (e) => {
    const { value, name } = e.target;
    setData((oldValue) => {
      return {
        ...oldValue,
        [name]: value,
      };
    });
  };

  const submithandler = () => {
    const submitData = {
      match_category_id: matchCat,
      match_id: matchName,
      tournament_name: data.tournament_name,
      court_type_id: courtType,
      tournament_key: data.tournament_key,
      match_type: data.match_type,
      umpire_id:"123"
    };

    console.log(submitData, "submitData");

    const token = localStorage.getItem("tokenTennis");

    const bearerToken = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axiosBaseURL
      .post(`tournaments/${location.state.id}`, submitData, bearerToken)
      .then((resp) => {
        // alert(resp.data.message);
        console.log(resp, "Daaataaata");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  const token1 = localStorage.getItem("tokenTennis");

  useEffect(() => {
    const bearerToken = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token1}`,
      },
    };

    axiosBaseURL.get('match_category', bearerToken)
      .then((resp) => {
        // alert(resp.data.message);
        console.log(resp.data.data, "Daaataaata");
        setcatData(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="admin_tournaments">
      <div className="admintournament_heading">
        <h2>Update Tournaments</h2>
        <button className="addtournament_btn">Update Tournament</button>
      </div>
      <div className="adminDash_toyurnametForm">
        <Box
          component="form"
          sx={{ "& > :not(style)": { m: 1 } }}
          noValidate
          className="ct_input"
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Tournament Name"
            className="text_inputFields"
            variant="outlined"
            value={data.tournament_name}
            name="tournament_name"
            onChange={chnageHandler}
          />
        </Box>

        <FormControl sx={{ m: 1, width: 500 }}>
          <InputLabel id="demo-multiple-checkbox-label">
            Match Category
          </InputLabel>
          <Select
            labelId="Match Category"
            id="demo-multiple-checkbox"
            multiple
            value={matchCat}
            name="matchCat"
            onChange={matchCatChnage}
            input={<OutlinedInput label="Match Category" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {catData.map((name) => (
              <MenuItem key={name.id} value={name.id}>
                <Checkbox
                  checked={matchCat.indexOf(name.match_category_name) > -1}
                />
                <ListItemText primary={name.match_category_name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, width: 500 }}>
          <InputLabel id="demo-multiple-checkbox-label">Match Name</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={matchName}
            onChange={handleChange}
            input={<OutlinedInput label="Match Name" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {matchCategoryApi.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={matchName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="tournament_fields">
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-chip-label">Court Type</InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            label="Court Type"
            // value={courtType}
            onChange={courtTypeChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {courtData.map((name) => (
              <MenuItem
                key={name.id}
                value={name.id}
                style={getStyles(name, courtTypeData, theme)}
              >
                {name.court_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box
          component="form"
          sx={{ "& > :not(style)": { m: 1, width: "50ch" } }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Tournament Key"
            variant="outlined"
            value={data.tournament_key}
            name="tournament_key"
            onChange={chnageHandler}
          />
        </Box>
      </div>
      <div>
        <Link to="/admindash/tournamentslisting">
          <button className="addtournament_btn" onClick={submithandler}>
            Update
          </button>
        </Link>
      </div>
    </div>
  );
};

export default UpdateTournament;
