import reactLogo from './assets/react.svg'
import './App.css'
import Header from './Components/Header/Header'
import { Routes,Route } from 'react-router-dom'
import Login from './Components/Login'
import Home from './Components/Home'
import CurrentMatch from './pages/FrontEnd/Current Match'
import UpCommingMatch from './pages/FrontEnd/UpCommingMatch'
import PreviousMatch  from './pages/FrontEnd/PrevviousMatches'
import Umpire from './pages/umpire'
import Umpiretournament from './pages/umpiretournament/index.jsx'
import AdminDash from './pages/AdminDashboard/AdminDash'
import CurrMatchScore from './pages/FrontEnd/CurrMatchesScore'
import Tournaments from './pages/AdminDashboard/Tournaments'
import MatchListing from './pages/AdminDashboard/Matche/MatchListing'
import Player from './pages/AdminDashboard/Player'
import AdminUmpire from './pages/AdminDashboard/Umpire'
import TournamentListing from './pages/AdminDashboard/Tournaments/TournamentListing'
import Match from './pages/AdminDashboard/Matche'
import TeamListing from './pages/AdminDashboard/Player/TeamListing'
import ListingUmpire from './pages/AdminDashboard/Umpire/ListingUmpire'
import MatchDetails from './pages/umpiretournament/MatchDetails'
import NewsSection from './Components/NewsSection'
import UpdateUmpire from './pages/AdminDashboard/Umpire/UpdateUmpire'
import UpdateCreateTeam from './pages/AdminDashboard/Player/UpdateCreateTeam'
import MatchUpdate from './pages/AdminDashboard/Matche/MatchUpdate'
import UpdateTournament from './pages/AdminDashboard/Tournaments/UpdateTournament'
import MatchDetasilsListing from './pages/umpiretournament/MatchDetasilsListing'
import EditScore from '../src/pages/AdminDashboard/EditScore'


function App() {
  
  return (
    <div className="App" id='app'>
      <Routes>
            <Route path="/" element={<Header/>}>
              <Route path='/' element={<Home/>}/>
              <Route path='/currentmatches' element={<CurrentMatch/>}/>
              <Route path='/upcommingmatches' element={<UpCommingMatch/>}/>
              <Route path='/previousmatches' element={<PreviousMatch/>}/>
              <Route path='/umpireapp' element={<Umpire/>}/>
              <Route path='/login' element={<Login/>}/>
              <Route path='/currMatchScore' element={<CurrMatchScore/>}/>
              <Route path='newsLink' element={<NewsSection/>}/>
          </Route>
          
              {/* Umpire App */}
          <Route path='/umpiretournament' element={<Umpiretournament/>}>
            <Route path='matchDetasilsListing' element={<MatchDetasilsListing/>}/>
            <Route path='matchScore' element={<MatchDetails/>}/>
          </Route>

              {/* Admin DashBoard */}

          <Route path='/admindash' element={<AdminDash/>}>
              <Route path='tournamentslisting' element={<TournamentListing/>}/>
              <Route path='tournaments' element={<Tournaments/>}/>
              <Route path='matcheslisting' element={<MatchListing/>}/>
              <Route path='match' element={<Match/>}/>
              <Route path='updateumpire' element={<UpdateUmpire/>}/>
              <Route path='updatetournament' element={<UpdateTournament/>}/>
              <Route path='matchupdate' element={<MatchUpdate/>}/>
              <Route path='createteam' element={<Player/>}/>
              <Route path='update-createteam' element={<UpdateCreateTeam/>}/>
              <Route path='teamListing' element={<TeamListing/>}/>
              <Route path='umpireListing' element={<ListingUmpire/>}/>
              <Route path='umpire' element={<AdminUmpire/>}/>
              <Route path='editScore' element={<EditScore/>}/>
          </Route>
      </Routes>
    </div>
  )
}

export default App
