import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Link} from 'react-router-dom'
// Mui Icons
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'; //Create New
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded'; //Edit
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'; //Delete
// Mui Dialoug box
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import './TeamListing.css'
import { useEffect } from 'react';
import axios from 'axios';
import axiosBaseURL from '../../../httpCommon';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const TeamListing = () => {
    const [open, setOpen] = React.useState(false);
    const [updatePop,setUpdatePop] = React.useState(false)
    const [mappedData,setMappedData ] = React.useState([])
    const [updateData,setUpdateData]  = React.useState()
    const [delData,setDelData] = React.useState()

    const handleClickOpen = (idd) => {
      setOpen(true);
      setDelData(idd)
    };

    const handleClose = () => {
      setOpen(false);
    };


  const token = localStorage.getItem('tokenTennis')


  useEffect(()=>{
    axiosBaseURL.get('/teams',{ headers: {"Authorization" : `Bearer ${token}`} })
      .then((resp) => {
        // alert(resp.data.message);
        console.log(resp.data.data,"Daaataaata")
        setMappedData(resp.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  },[])

  const handleUpdate = (row)=>{
    setUpdatePop(true);
    setUpdateData(row)
  }

  const  DelHandler = (id)=>{
    handleClose(false)

    const bearerToken = {headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      Authorization: `Bearer ${token}`
    }}
      axiosBaseURL.delete(`/teams/${id.id}`, bearerToken)
        .then((resp) => {
          // alert(resp.data.message);
          console.log(resp,"Daaataaata")


          if(resp.data.data === true){
            axiosBaseURL.get('/teams',{ headers: {"Authorization" : `Bearer ${token}`} })
              .then((resp) => {
                // alert(resp.data.message);
                console.log(resp.data.data,"Daaataaata")
                setMappedData(resp.data.data)
              })
              .catch((err) => {
                console.log(err);
              });

          }
        })
        .catch((err) => {
          console.log(err);
        }); 

  }

  return (
    <div className='tournamentListing'>
       <div className='tournamentListing_header'>
            <h3>Team Listing</h3>
          <Link to='/admindash/createteam'>
            <button className='trnamnet_btn'>Create New Match <AddCircleOutlineRoundedIcon/></button>
          </Link>
       </div>
       <div className='tournamentLi_table'>
       <TableContainer component={Paper}>
      <Table  aria-label="simple table">
        <TableHead >
          <TableRow  >
            <TableCell >Team Name</TableCell>
            <TableCell align="right">Team Location</TableCell>
            <TableCell align="right">Player 1</TableCell>
            <TableCell align="right">Player 2</TableCell>
            
            <TableCell align="right">Update/Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className='tournament_tablebody'>
          {mappedData.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              className='tournament_tableRow'
            >
              <TableCell component="th" scope="row">
                {row.team_name}
              </TableCell>
              <TableCell align="right">{row.team_location}</TableCell>
              <TableCell align="right">{row.player_name_one}</TableCell>
              <TableCell align="right">{row.player_name_two}</TableCell>
            
              <TableCell align="right">
               <BorderColorRoundedIcon className='tournamnet_edit_' onClick={()=>handleUpdate(row)}/>
               <DeleteRoundedIcon className='tournamnet_delbtns'  onClick={()=>handleClickOpen(row)}/>
              </TableCell>

            </TableRow>
          ))}
          
        </TableBody>
      </Table>
    </TableContainer>
       </div>
       <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      > 
      <div className='team_dialogbox'>
      <h4>Delete This Data?</h4>
        <div className='diabuttonsWrapper'>
            <button className='dialogButton_yes' onClick={()=>DelHandler(delData)}>Yes</button>
            <button className='dialogButton_no' onClick={()=>handleClose(false)}> No</button>
        </div>
        </div>
      </Dialog>
    <Dialog
        open={updatePop}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      > 
      <div className='team_dialogbox'>
      <h4>Edit This Data?</h4>
        <div className='diabuttonsWrapper'>
            <button className='EditdialogButton_yes' > <Link to='/admindash/update-createteam' 
            state={updateData} >Yes</Link></button>
            <button className='dialogButton_no' onClick={()=>setUpdatePop(false)}> No</button>
        </div>
        </div>
      </Dialog>
    </div>
    </div>
  )
}
export default TeamListing;