import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import axiosBaseURL from '../../../httpCommon'

const index = () => {


const [umpire,setUmpire] = useState({
  umpire_name:"",
  umpire_location:"",
  umpire_image:"",
  umpire_gender:"",
  umpire_email:"",
  umpire_contact_number:"",
  umpire_experince:"",
})

const [umpireData,setUmpireData]=useState([])

const changeHandler = (e)=>{
  const {name,value} =  e.target;
  setUmpire((oldValue)=>{
    return{
      ...oldValue,
      [name]:value
    }
  })
} 

const submitHandler = ()=>{
  
  setUmpireData([...umpireData,umpire])

  const token = localStorage.getItem('tokenTennis')

  const bearerToken = {headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  }}


    axiosBaseURL.post('/umpires', umpire, bearerToken)
      .then((resp) => {
        // alert(resp.data.message);
        console.log(resp,"Daaataaata")
      })
      .catch((err) => {
        console.log(err);
      });  
}

  return (
    <div className="createMatch_">
      <div className="createMatch_header">
        <h3>Create Umpire</h3>
        <button className="creat_matchbtn">Create New Umpire</button>
      </div>
      <div className="createMatch_fields">
        <Box
          sx={{
            width: "95%",
            maxWidth: "100%",
            padding: "15px 0",
          }}
        >
          <label>Umpire Name</label>
          <TextField
            fullWidth
            label=""
            id="fullWidth"
            placeholder= "Name"
            value={umpire.umpire_name}
            name="umpire_name"
            onChange={changeHandler}
          />
        </Box>
        <Box
          sx={{
            width: "95%",
            maxWidth: "100%",
            padding: "15px 0",
          }}
        >
          <label> Umpire Location</label>
          <TextField
            fullWidth
            label=""
            id="fullWidth"
            placeholder="Location"
            value={umpire.umpire_location}
            name="umpire_location"
            onChange={changeHandler}
          />
        </Box>
        <Box
          sx={{
            width: "95%",
            maxWidth: "100%",
            padding: "15px 0",
          }}
        >
          <label> Umpire Email</label>
          <TextField
            fullWidth
            label=""
            id="fullWidth"
            placeholder="Umpire Email"
            value={umpire.umpire_email}
            name="umpire_email"
            onChange={changeHandler}
          />
        </Box>
      </div>
      <div className="create_match_btns">
          <Link to='/admindash/umpireListing'>
            <button className="crt_match_save" onClick={submitHandler}>SAVE</button>
            </Link>
          {/* </Link> */}
            <button className="crt_match_cancle">CANCLE</button>
      </div>
    </div>
  );
};

export default index;
