import { configureStore } from '@reduxjs/toolkit'
import headerSlice from './headerSlice/index'
import umcommingSlice from './UpcommingApi'
import fatchNews from './NewsApi'
export const store = configureStore({
  reducer: {
    headerSlice,
    umcommingSlice,
    fatchNews
  },
})