import React from "react";
import "./CurrentMatch.css";
import { useState, useEffect } from "react";
import GradeIcon from "@mui/icons-material/Grade";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PersonIcon from "@mui/icons-material/Person"; //Singles
import flag1 from "../../../assets/united-kingdom.png";
import flag2 from "../../../assets/united-states.png";
import { Link } from "react-router-dom";
import { Favourite } from "./MatchJson";
import Carousel from "react-bootstrap/Carousel";
import GroupIcon from "@mui/icons-material/Group";
import axios from "axios";
import axiosBaseURL from "../../../httpCommon";

const index = () => {
  const [addFav, setAddFav] = useState(true);

  const handleclick = () => {
    if (addFav === true) {
      setAddFav(false);
    } else if (addFav === false) {
      setAddFav(true);
    }
  };

  const [data, setData] = useState([]);

  const WomensSingle = data.filter((e) => {
    return e.match_category_id === "2";
  });
  const MenSingle = data.filter((e) => {
    return e.match_category_id === "1";
  });
  const WomenDouble = data.filter((e) => {
    return e.match_category_id === "3";
  });
  const MensDouble = data.filter((e) => {
    return e.match_category_id === "4";
  });

  const bearerToken = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  useEffect(() => {
    axiosBaseURL
      .get("/live-match", bearerToken)
      .then((resp) => {
        setData(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(MenSingle, "MenSingle");

  return (
    <div className="currMatch_Container">
      <div className="currMatch_Container_ch">
        {/* ===== Men's Single Match=== */}
        <div className="CurrFavDiv">
          <div className="CurrFavDiv_header">
            <PersonIcon className=" headerCurr_icon" onClick={handleclick} />
            <h5>Men's Single Matches</h5>
          </div>
          <div className="matchescurr_sc">
            {MenSingle.length === 0 ? (
              <div className="nodata">
                <h3> No Matches For This Category</h3>
              </div>
            ) : (
              <Carousel className="currMatchCarousel">
                {MenSingle.map((currvalue, index) => {
                  return (
                    <Carousel.Item>
                      <Link to="/CurrMatchScore" state={currvalue}>
                        <div className="team_list" key={index}>
                          <div className="match_result">
                            <span>{currvalue.match_time}</span>
                          </div>
                          <div className="match_team">
                            <div className="match_team_1">
                              <div className="team_flags">
                                <img src={flag1} alt="" className="flag1" />
                              </div>
                              <div className="team_name">
                                <span>{currvalue.team_one.team_name}</span>
                              </div>
                            </div>
                            <div className="team_name_vs"> Vs </div>
                            <div className="match_team_1">
                              <div className="team_flags">
                                <img src={flag2} alt="" className="flag1" />
                              </div>
                              <div className="team_name">
                                <span> {currvalue.team_two.team_name}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            )}
          </div>
        </div>
        {/* ===== Men's Double Match=== */}
        <div className="CurrFavDiv">
          <div className="CurrFavDiv_header">
            <GroupIcon className=" headerCurr_icon" onClick={handleclick} />
            <h5>Men's Double Matches</h5>
          </div>
          <div className="matchescurr_sc">
            {MensDouble.length === 0 ? (
              <div className="nodata">
                <h3> No Matches For This Category</h3>
              </div>
            ) : (
              <Carousel className="currMatchCarousel">
                {MensDouble.map((currvalue) => {
                  return (
                    <Carousel.Item>
                      <Link to="/CurrMatchScore" state={currvalue}>
                        <div className="team_list">
                          <div className="favriout_icon">
                            {addFav ? (
                              <StarBorderIcon onClick={handleclick} />
                            ) : (
                              <GradeIcon
                                className="favIcon"
                                onClick={handleclick}
                              />
                            )}
                          </div>
                          <div className="match_result">
                            <span>{currvalue.match_time}</span>
                          </div>
                          <div className="match_team">
                            <div className="match_team_1">
                              <div className="team_flags">
                                <img src={flag1} alt="" className="flag1" />
                              </div>
                              <div className="team_name">
                                <span> {currvalue.team_one.team_name}</span>
                              </div>
                            </div>
                            <div className="team_name_vs"> Vs </div>
                            <div className="match_team_2">
                              <div className="team_flags">
                                <img src={flag2} alt="" className="flag1" />
                              </div>
                              <div className="team_name">
                                <span>{currvalue.team_two.team_name}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            )}
          </div>
        </div>
        {/* ===== Women's Single Match=== */}
        <div className="CurrFavDiv">
          <div className="CurrFavDiv_header">
            <PersonIcon className=" headerCurr_icon" onClick={handleclick} />
            <h5>Women's Single Matches</h5>
          </div>

          <div className="matchescurr_sc">
            {WomensSingle.length === 0 ? (
              <div className="nodata">
                <h3> No Matches For This Category</h3>
              </div>
            ) : (
              <Carousel className="currMatchCarousel">
                {WomensSingle.map((currvalue) => {
                  return (
                    <Carousel.Item>
                      <Link to="/CurrMatchScore" state={currvalue}>
                        <div className="team_list">
                          <div className="favriout_icon">
                            {addFav ? (
                              <StarBorderIcon onClick={handleclick} />
                            ) : (
                              <GradeIcon
                                className="favIcon"
                                onClick={handleclick}
                              />
                            )}
                          </div>
                          <div className="match_result">
                            <span>{currvalue.match_time}</span>
                          </div>
                          <div className="match_team">
                            <div className="match_team_1">
                              <div className="team_flags">
                                <img src={flag1} alt="" className="flag1" />
                              </div>
                              <div className="team_name">
                                <span> {currvalue.team_one.team_name}</span>
                              </div>
                            </div>
                            <div className="team_name_vs"> Vs </div>
                            <div className="match_team_2">
                              <div className="team_flags">
                                <img src={flag2} alt="" className="flag1" />
                              </div>
                              <div className="team_name">
                                <span>{currvalue.team_two.team_name}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            )}
          </div>
        </div>
        {/* ===== Women's Double Match=== */}
        <div className="CurrFavDiv">
          <div className="CurrFavDiv_header">
            <GroupIcon className=" headerCurr_icon" onClick={handleclick} />
            <h5>Women's Double Matches</h5>
          </div>

          <div className="matchescurr_sc">
            {WomenDouble.length === 0 ? (
              <div className="nodata">
                <h3> No Matches For This Category</h3>
              </div>
            ) : (
              <Carousel className="currMatchCarousel">
                {WomenDouble.map((currvalue) => {
                  return (
                    <Carousel.Item>
                      <Link to="/CurrMatchScore">
                        <div className="team_list">
                          <div className="favriout_icon">
                            {addFav ? (
                              <StarBorderIcon onClick={handleclick} />
                            ) : (
                              <GradeIcon
                                className="favIcon"
                                onClick={handleclick}
                              />
                            )}
                          </div>
                          <div className="match_result">
                            <span>{currvalue.match_time}</span>
                          </div>
                          <div className="match_team">
                            <div className="match_team_1">
                              <div className="team_flags">
                                <img src={flag1} alt="" className="flag1" />
                              </div>
                              <div className="team_name">
                                <span> {currvalue.team_one.team_name}</span>
                              </div>
                            </div>
                            <div className="team_name_vs"> Vs </div>
                            <div className="match_team_2">
                              <div className="team_flags">
                                <img src={flag2} alt="" className="flag1" />
                              </div>
                              <div className="team_name">
                                <span>{currvalue.team_two.team_name}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
