import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
// Mui Icons
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded"; //Create New
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded"; //Edit
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded"; //Delete
// Mui Dialoug box
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { useEffect } from "react";
import axiosBaseURL from "../../../httpCommon";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const MatchListing = () => {
  function createData(
    match_category,
    team_name,
    umpire_name,
    courtname,
    tournament_key,
    TournamentCode,
    matchType,
    match_location
  ) {
    return {
      match_category,
      team_name,
      umpire_name,
      courtname,
      tournament_key,
      TournamentCode,
      matchType,
      match_location,
    };
  }
  const [updatePop, setUpdatePop] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [delData,setDelData] = React.useState()
  const [dataDia,setDataDia] = React.useState()
  const [mappedData, setMappedData] = React.useState([]);

  const handleClickOpen = (row) => {
    setOpen(true);
    setDelData(row)
  };



  const handleClose = () => {
    setUpdatePop(false);
  };


  const handleUpdate = (row)=>{
    setUpdatePop(true);
    setDataDia(row)
  }

const delhandler =(id)=>{
  setOpen(false)



  const bearerToken = {headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    Authorization: `Bearer ${token}`
  }}
  
    axiosBaseURL.delete(`/matches-delete/${id.id}`, bearerToken)
      .then((resp) => {
        // alert(resp.data.message);
        console.log(resp,"Daaataaata")
        if(resp.data.data === true){
          axiosBaseURL.get('/matches', { headers: { Authorization: `Bearer ${token}` } })
            .then((resp) => {
              // alert(resp.data.message);
              console.log(resp.data.data, "eee");
              setMappedData(resp.data.data);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      }); 

      setOpen(false)
}

  const token = localStorage.getItem("tokenTennis");

  useEffect(() => {
    axiosBaseURL.get('/matches', { headers: { Authorization: `Bearer ${token}` } })
      .then((resp) => {
        // alert(resp.data.message);
        console.log(resp.data.data, "eee");
        setMappedData(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  
  return (
    <div className="tournamentListing">
      <div className="tournamentListing_header">
        <h3>Match Listing</h3>
        <Link to="/admindash/match">
          <button className="trnamnet_btn">
            Create New Match <AddCircleOutlineRoundedIcon />
          </button>
        </Link>
      </div>
      <div className="tournamentLi_table">
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
              <TableCell align="right">Match date</TableCell>
              <TableCell align="right">Match Time</TableCell>
                <TableCell>Match Name</TableCell>
                <TableCell align="right">Match Category</TableCell>
                <TableCell align="right">Team Name 1</TableCell>
                <TableCell align="right">Team Name 2</TableCell>
                <TableCell align="right">Court</TableCell>
                <TableCell align="right">Match Location</TableCell>
                <TableCell align="right">Umpire Name</TableCell>
                <TableCell align="right">Update/Delete</TableCell>
              </TableRow>
            </TableHead>

            <TableBody className="tournament_tablebody">
              {mappedData.map((row,index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  className="tournament_tableRow"
                >
                  <TableCell align="right">{row.matchDate}</TableCell>
                  <TableCell align="right">{row.match_time}</TableCell>
                  <TableCell component="th" scope="row">
                    {row.match_name}
                  </TableCell>
                  <TableCell align="right">
                   {row.matchCategory.match_category_name}
                  </TableCell>
                  <TableCell align="right">
                     {row.teamTwoData.team_name}
                  </TableCell>
                  <TableCell align="right">
                  {row.teamOneData.team_name}
                  </TableCell>
                  <TableCell align="right">
                  {/* {row.court.court_name} */}
                  {row.court.court_name}
                    </TableCell>
                  <TableCell align="right">{row.match_location}</TableCell>
                  <TableCell align="right">

                    {row.umpireData.umpire_name}
                   
                  </TableCell>

                  <TableCell align="right">
                    <BorderColorRoundedIcon
                      className="tournamnet_edit_"
                      onClick={()=>handleUpdate(row)}
                    />
                    <DeleteRoundedIcon
                      className="tournamnet_delbtns"
                      onClick={()=>handleClickOpen(row)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="team_dialogbox">
            <h4> Delete This Data?</h4>
            <div className="diabuttonsWrapper">
              <button className="dialogButton_yes" onClick={()=>delhandler(delData)}>
                Yes
              </button>
              <button className="dialogButton_no">No</button>
            </div>
          </div>
        </Dialog>

        <Dialog
          open={updatePop}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="team_dialogbox">
            <h4>Edit This Data?</h4>
            <div className="diabuttonsWrapper">
              <button className="EditdialogButton_yes">
                <Link to="/admindash/matchupdate" state={dataDia} >Yes</Link>
              </button>
              <button
                className="dialogButton_no"
                onClick={handleClose}
              >
                No
              </button>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};
export default MatchListing;
