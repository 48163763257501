import React from "react";
import "./CurrMatchesScore.css";
import { useState, useEffect } from "react";
import GradeIcon from "@mui/icons-material/Grade";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PersonIcon from "@mui/icons-material/Person"; //Singles
import flag1 from "../../../assets/united-kingdom.png";
import flag2 from "../../../assets/united-states.png";
import { Link } from "react-router-dom";
import { Favourite } from "../Current Match/MatchJson";
import Carousel from "react-bootstrap/Carousel";
import GroupIcon from "@mui/icons-material/Group";
import { useLocation } from "react-router-dom";
import axiosBaseURL from "../../../httpCommon";

const points = [
  {
    gamepoint: 2,
    team1: [
      {
        setpoint: 6,
      },
      {
        setpoint: 6,
      },
    ],
  },
  {
    gamepoint: 2,
    team2: [
      {
        setpoint: 3,
      },
      {
        setpoint: 6,
      },
    ],
  },
];

const index = () => {
  const location = useLocation();



  const [data, setData] = useState([]);
 


  const bearerToken = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const match_iddata = {
    match_id:location.state.id
  }
  
useEffect(()=>{
  axiosBaseURL.post(`/team-score-list`,match_iddata, bearerToken)
  .then((resp) => {
    console.log(resp.data.data,"dddaaat")
    setData(resp.data.data[0]);
  })
  .catch((err) => {
    console.log(err);
  });

},[])
   

  // const teamOneScore = location.state.team_one.score.map((map) => {
  //   return map.score_five;
  // });
  // const teamTwoScore = location.state.team_two.score.map((map) => {
  //   return map.score_five;
  // });



  console.log(location.state.score[0], "location");
  const MatchData = location.state;

  const [addFav, setAddFav] = useState(true);

  const handleclick = () => {
    if (addFav === true) {
      setAddFav(false);
    } else if (addFav === false) {
      setAddFav(true);
    }
  };



console.log(data,"dddfff")
  return (
    <>
      <div className="cardScoreContainer">
        <div className="card-list">
          <div class="card">
            <div class="team_details">
              <div class="team_logo">
                <div class="team_one-scores">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHT9hSTrhnw5_FvXT219zV-Ge0aXmk-P1kZA&usqp=CAU"
                    alt="An orange painted blue, cut in half laying on a blue background"
                    class="card-image"
                  />
                  <div>
                    <span>Team Name : {location.state.team_one.team_name}</span>
                  </div>

                  <div>
                    <span>{data.score_team_one_one}</span>
                    <span>{data.score_team_one_two}</span>
                    <span>{data.score_team_one_three}</span>
                    <span>{data.score_team_one_four}</span>
                    <span>{data.score_team_one_five}</span>
                  </div>
                </div>
                <p>VS</p>
                <div class="team_two-scores">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHBW-DILSlsMF0kPonRZOKNJz9bKNcw8InCw&usqp=CAU"
                    alt="An orange painted blue, cut in half laying on a blue background"
                    class="card-image"
                  />
                  <div>
                    <span>Team Name : {location.state.team_two.team_name}</span>
                  </div>

                  <div>
                  <span>{data.score_one_teamTwo}</span>
                    <span>{data.score_two_teamTwo}</span>
                    <span>{data.score_three_teamTwo}</span>
                    <span>{data.score_four_teamTwo}</span>
                    <span>{data.score_five_teamTwo}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* 
            <div class="content">
              <div class="score-card-footer">
                <div class="card-meta card-meta--views">
                  {teamOneScore === 6 || teamTwoScore === 6 ? (
                    <>
                      Match Status :{" "}
                      <span style={{ color: "green" }}> &nbsp; Live </span>
                    </>
                  ) : (
                    <>
                      Match Status :{" "}
                      <span style={{ color: "red" }}> &nbsp; Finished </span>
                    </>
                  )}
                  <span class="available-pointer"></span>
                </div>
                <div class="card-meta card-meta--date">
                  Match Date : {location.state.match_date}
                </div>
              </div>
              <div class="score-card-header">
                <a href="#">Match Name : {location.state.match_name}</a>
              </div>
              <div class="score-card-header">
                <a href="#">
                  Match Category :
                  {location.state.match_category.match_category_name}
                </a>
              </div>
              <div class="score-card-header">
                <a href="#">
                  Match Umpire Name : {location.state.umpire.umpire_name}
                </a>
              </div>
              <div class="score-card-header">
                <a href="#">
                  Teams : {location.state.team_one.team_name},
                  {location.state.team_two.team_name}
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default index;
