import React from "react";
import "./Tournament.css";
import Box from "@mui/material/Box";
import Chip from '@mui/material/Chip';
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { useState } from "react";
// Mui DropDown
import "./Tournament.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { useTheme } from '@mui/material/styles';
import { useEffect } from "react";
import axios from "axios";
import axiosBaseURL from '../../../httpCommon'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

const courtTypeData = [
  "Clay Court",
  "Hard Court",
  "Grass Court",
  "Carpet Court"
]

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const index = () => {

  const [open,setOpen] = useState(false)
  const [umpireDrop,setUmpireDrop] = useState(false)
  const handleClose = () => {
    setOpen(false);
  };
  const handleUmpClose=()=>{
    setUmpireDrop(false)
  }
  
  const handleOpen = () => {
    setOpen(true);
  };
  const handleUmpOpen = () => {
    setUmpireDrop(true)
  };

  const token = localStorage.getItem('tokenTennis')
  const [courtData,setCourtData] = useState([])


  

  useEffect(()=>{
    axiosBaseURL
      .get('/courts',{ headers: {"Authorization" : `Bearer ${token}`},Accept:'application/json' })
      .then((resp) => {
        // alert(resp.data.message);
        console.log(resp.data.data,"Daaataaata123")
        setCourtData(resp.data.data)
       
      })
      .catch((err) => {
        console.log(err);
      });
  },[])

  
  const theme = useTheme();
  const [matchName, setMatchName] = React.useState([]);
  const [matchCat, setMatchCat] = React.useState([]);
  const [cat_name, setcat_name] = React.useState([]);
  const [courtType, setcourtType] = React.useState('');
  const [umpireDetails,setUmpireDetails] = React.useState([])


console.log(umpireDetails,"umpireDetails")



  console.log(matchCat,"matchCat")
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMatchName(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const matchCatChnage = (event) => {
    const {
      target: { value },
    } = event;
    setMatchCat(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const courtTypeChange = (event) => {

    setcourtType(event.target.value)

  };

  const [umpireName,setUmpireName] = useState([])

  const umpireNameChange = (event)=>{
    setUmpireDetails(event.target.value)
    }

  const [data,setData]=useState({
    tournament_name:"",
    tournament_key:"",
    match_type:"",
    tournament_code:""
  })



  console.log(data,"fffff")

// tournament_name:Tour 12265
// court_type_id:1
// match_category_id[]:1
// match_category_id[]:2
// tournament_key:TourKey
// match_id[]:1
// match_id[]:2

  const [dataStore,setDataStore] = useState([])
  const chnageHandler = (e)=>{
    const {value,name} = e.target;
    setData((oldValue)=>{
      return{
        ...oldValue,
        [name]:value,
      }
    })}

  const submithandler=()=>{

    const submitData = {
      match_category_id:matchCat,
      match_id:matchName,
      tournament_name:data.tournament_name,
      court_type_id:courtType,
      tournament_key:data.tournament_key,
      tournament_code:"readablecode",
      match_type:data.match_type,
      umpire_id:umpireDetails
    }
    
    console.log(submitData,"submitData")

    const token = localStorage.getItem('tokenTennis')

    const bearerToken = {headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    }}


      axiosBaseURL.post('/tournaments', submitData, bearerToken)
        .then((resp) => {
          // alert(resp.data.message);
          console.log(resp,"Daaataaata456464")
        })
        .catch((err) => {
          console.log(err);
        });  
  }

  const [matchnameApi,setMatchNameApi] = useState([])
  const [cat,setcat] = useState([])

  


    const matchApiData =  matchnameApi.map((map)=>{
      return map.match_name
    })

  useEffect(()=>{
    axiosBaseURL.get('/matches',{ headers: {"Authorization" : `Bearer ${token}`},Accept:'application/json' })
      .then((resp) => {
        // alert(resp.data.message);
       setMatchNameApi(resp.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  },[])


  useEffect(()=>{


    axiosBaseURL.get('/umpires',{ headers: {"Authorization" : `Bearer ${token}`},Accept:'application/json' })
      .then((resp) => {
        // alert(resp.data.message);
        setUmpireName(resp.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  },[])

  useEffect(()=>{
    
    axiosBaseURL.get('/match_category',{ headers: {"Authorization" : `Bearer ${token}`},Accept:'application/json' })
      .then((resp) => {
       setcat(resp.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  },[])
  
  
  return (
    <div className="admin_tournaments">
      <div className="admintournament_heading">
        <h2>Create Tournaments</h2>
        <button className="addtournament_btn">Add Tournament</button>
      </div>
      <div className="adminDash_toyurnametForm">

      <Box
          component="form"
          sx={{ "& > :not(style)": { m: 1}}}
          noValidate
          className="ct_input"
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Tournament Name"
            className="text_inputFields"
            variant="outlined"
            value={data.tournament_name}
            name="tournament_name"
            onChange={chnageHandler}
          />
        </Box>

        <FormControl sx={{ m: 1, width: 500 }}>
          <InputLabel id="demo-multiple-checkbox-label">Match Category</InputLabel>
          <Select
            labelId="Match Category"
            id="demo-multiple-checkbox"
            multiple
            value={matchCat}
            name="matchCat"
            onChange={matchCatChnage}
            input={<OutlinedInput label="Match Category" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
           {
            console.log(cat,"cat123")
           }

            {cat.map((name) => (
              <MenuItem key={name.id} value={name.id}>
                <Checkbox checked={matchCat.indexOf(name.match_category_name) > -1} />
                <ListItemText primary={name.match_category_name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, width: 500 }}>
        <InputLabel id="demo-multiple-checkbox-label">Match Name</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={matchName}
          onChange={handleChange}
          input={<OutlinedInput label="Match Name" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {matchApiData.map((name,index) => (
            <MenuItem key={index} value={name}>
              <Checkbox checked={matchName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </div>

      <div className="tournament_fields">
        <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-chip-label">Court Type</InputLabel>
          <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          label="Court Type"
          value={courtType}
          onChange={courtTypeChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {courtData.map((name,index) => (
            <MenuItem
              key={index}
              value={name.id}
              style={getStyles(name, courtTypeData, theme)}
            >
              {name.court_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

        <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-chip-label">Umpire Name</InputLabel>
          <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={umpireDrop}
          onClose={handleUmpClose}
          onOpen={handleUmpOpen}
          label="Umpire Name"
          value={umpireDetails}
          onChange={umpireNameChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {umpireName.map((map,index) => (
            <MenuItem
              key={index}
              value={map.id}
              style={getStyles(name, courtTypeData, theme)}
            >
              {map.umpire_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

        <Box
          component="form"
          sx={{ "& > :not(style)": { m: 1, width: "50ch" } }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Tournament Key"
            variant="outlined"
            value={data.tournament_key}
            name="tournament_key"
            onChange={chnageHandler}
          />
        </Box>

      </div>
      <div>
        <Link to="/admindash/tournamentslisting">
          <button className="addtournament_btn"  onClick={submithandler}>Save</button>
        </Link>
      </div>
    </div>
  );
};

export default index;
