import React from "react";
import "./UmpireTour.css";
// MUI DROPDOWN
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
//
import MatchBanner from "../../assets/images/match_banner.png";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import axiosBaseURL from "../../httpCommon";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MatchDetasilsListing = () => {

  const { state } = useLocation();
  const [AssignMatch, setAssignMatch] = useState([]);

  
  useEffect(() => {
    const data = {
      umpire_id: state.id.umpire.id,
    };
    
    const bearerToken = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    axiosBaseURL.post('/assign-match', data, bearerToken).then((resp) => {
        // alert(resp.data.message);
        setAssignMatch(resp.data.data);
        // console.log(resp.data.data,"jhgvfjcgvlcjkv")
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [startDate, setStartDate] = useState(new Date());

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const clickHandler = (currValue) => {

    const data = {
      match_id: currValue.id,
      team_id: [currValue.teamOneData.id, currValue.teamTwoData.id],
    };
    const bearerToken = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    axiosBaseURL
      .post('/match-score', data, bearerToken)
      .then((resp) => {
        console.log(resp, "DDDDDDDDDDDDDD");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="umpire_ch">
      <div className="umpire_ch_header">
{
  (AssignMatch.length===0)?"":
  <h3>List Of Matches Assigned to :{AssignMatch[0].umpire_name}</h3>
}
             
   
      </div>
     
      <div className="match_detailWrapeer_pr">
        <div className="match_detailWrapeer">

         {AssignMatch.map((currValue, index) => {
            return (
              <Link to="/umpiretournament/matchScore" state={currValue}>
                 <div className="match_details" key={index} onClick={()=>clickHandler(currValue)}>
                   <div className="_umpire_matchType">
                     {currValue.match_name}
                   </div>
                   <div className="ump_li_match_date">
                     {currValue.match_date}
                     <span style={{margin:"10px"}}>{currValue.match_time}</span>
                   </div>
                   <div className="match_poster">
                     <img src={MatchBanner} alt="" />
                   </div>
                 </div>
               </Link>
            );
          })}

        </div>
      </div>
    </div>
  );
};

export default MatchDetasilsListing;
