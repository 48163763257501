import React, { useState } from "react";
import "./umpirelogin.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { headerTrue } from '../../Redux/headerSlice/index'
import { useSelector } from "react-redux";
import axiosBaseURL from "../../httpCommon";
const index = ({setOpen}) => {

  const [joinData,setJoinData] = useState({
    tournament_code:"",
  })




   const dispatch = useDispatch()
    const navigate = useNavigate();
    const Selector = useSelector((state)=>state.headerSlice);

    const chnageHandler = (e) => {
      const { value, name } = e.target;
      setJoinData((oldvalue) => {
        return {
          ...oldvalue,
          [name]: value,
        };
      });
    };

    const [ump,setump] = useState('')

    console.log(ump,"setUmpireId")

    const[data,setdata] = useState('')
    console.log(data,"data")

    const HandleClickJoin = () =>{
      setOpen(false)
      if(Selector.value === false){
       dispatch(headerTrue(true))
    }

      const bearerToken = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
       },
     };

    axiosBaseURL.post('/join-match', joinData, bearerToken)
        .then((resp) => {
         console.log(resp.data.data,"resp")
         setdata(resp.data.data)
        
          if(resp.data.message === "Umpire Loggedin Successfully."){
           console.log(resp.data.data,"resp")
           
                navigate("/umpiretournament/matchDetasilsListing",{
                 state:{id:resp.data.data}
                })
          } 
        })
        
        .catch((err) => {
          console.log(err);
         alert("Join Match Failed Please Enter Valid Code")
        });
  }
  return (
    <div className="umpire_login">
      <div className="umpire_header">
        <h3>Umpire</h3>
      </div>
      <div className="umpire_content">
        <p>Enter Tournament Code</p>
        <input 
        type="text"
        value={joinData.tournament_code}
        name="tournament_code"
        onChange={chnageHandler}
        autocomplete="off"
         />
        <button 
        onClick={HandleClickJoin}
        >Join</button>
      </div>
    </div>
  );
};

export default index;
