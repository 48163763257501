import React from "react";
import "./MatchDetails.css";
import MatchBanner from "../../assets/images/match_banner.png";
import team1 from "../../assets/united-kingdom.png";
import AddIcon from "@mui/icons-material/Add";
import ReplayIcon from "@mui/icons-material/Replay";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Winnerlogo from "../../assets/images/Winner.svg";
import { Link } from "react-router-dom";
// Mui DropDown
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import axiosBaseURL from "../../httpCommon";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const dataOne = () => {
  let dataOnelist = localStorage.getItem("dataOne");
  console.log(dataOnelist);
};

// setInterval(() => {
//   let time = new Date();
//   let hours = time.getHours();
//   let minutes = time.getMinutes();
//   let seconds = time.getSeconds();
//   let amPm = "";

//   if (hours > 12) {
//     hours = hours - 12;
//     amPm = "PM";
//   } else if (hours == 0) {
//     hours = 12;
//     amPm = "AM";
//   } else {
//     amPm = "AM";
//   }
//   hours = hours > 10 ? hours : "0" + hours;
//   minutes = minutes > 10 ? minutes : "0" + minutes;
//   seconds = seconds > 10 ? seconds : "0" + seconds;

//   document.getElementById(
//     "digital-clock"
//   ).innerHTML = `${hours}:${minutes}:${seconds} ${amPm}`;
// }, 1000);

const MatchDetails = () => {
  const State = useLocation();
  let open = false;

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  console.log(
    State.state,

    "scoredata1"
  );

  const handleClose = () => {
    setOpen(false);
  };
  
  // const team1=JSON.parse(localStorage.getItem("team1"))

  // const [scoredata1, setscoreData1] = useState([]);
  // const [scoredata2, setscoreData2] = useState([]);

  const [gameOne, setGameOne] = useState(0);
  const [gameTwoOne, setGameTwoOne] = useState(0);
  const [gameThreeOne, setGameThreeOne] = useState(0);
  const [gameFoureOne, setGameFoureOne] = useState(0);
  const [gameFiveOne, setGameFiveOne] = useState(0);

  const [gameTwo, setGameTwo] = useState(0);
  const [gameTwoTwo, setGameTwoTwo] = useState(0);
  const [gameThreeTwo, setGameThreeTwo] = useState(0);
  const [gameFoureTwo, setGameFoureTwo] = useState(0);
  const [gameFiveTwo, setGameFiveTwo] = useState(0);

  const bearerToken = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      RetryAfter: 3600,
    },
  };

  const teamid = {
    match_id: State.state.id,
  };

  useEffect(() => {
    axiosBaseURL
      .post("/team-score-list", teamid, bearerToken)
      .then((resp) => {
        console.log(resp.data.data, "resp");

        setGameOne(+resp.data.data[0].score_team_one_one);
        setGameTwoOne(+resp.data.data[0].score_team_one_two);
        setGameThreeOne(+resp.data.data[0].score_team_one_three);
        setGameFoureOne(+resp.data.data[0].score_team_one_four);
        setGameFiveOne(+resp.data.data[0].score_team_one_five);

        setGameTwo(+resp.data.data[0].score_one_teamTwo);
        setGameTwoTwo(+resp.data.data[0].score_two_teamTwo);
        setGameThreeTwo(+resp.data.data[0].score_three_teamTwo);
        setGameFoureTwo(+resp.data.data[0].score_four_teamTwo);
        setGameFiveTwo(+resp.data.data[0].score_five_teamTwo);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const data = {
    team_id: State.state.team_one_id,
    score_one: gameOne,
    score_two: gameTwoOne,
    score_three: gameThreeOne,
    score_four: gameFoureOne,
    score_five: gameFiveOne,
  };


  setTimeout(useEffect(() => {
    const bearerToken = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    axiosBaseURL
      .post(`/score-update/${State.state.score_one_id}`, data, bearerToken)
      .then((resp) => {
        console.log(resp, "djhbcxzjn");
      })
      .catch((err) => {
        console.log(err);
      });
  }, [data]), 1000);

  const data1 = {
    team_id: State.state.team_two_id,
    score_one: gameTwo,
    score_two: gameTwoTwo,
    score_three: gameThreeTwo,
    score_four: gameFoureTwo,
    score_five: gameFiveTwo,
  };
  setTimeout(useEffect(() => {
    const bearerToken = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    axiosBaseURL
      .post(`/score-update/${State.state.score_two_id}`, data1, bearerToken)
      .then((resp) => {
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [data1]), 1000);


  const increaseScore = () => {
    if (gameOne < 6) {
      setGameOne(gameOne + 1);
    }
    // To Stop Last Input Counting
    if (gameOne === 6 || gameTwo === 6) {
      setGameOne(gameOne);
      setGameTwo(gameTwo);
    }

    // Second Input Counting
    if (gameTwoOne < 6 && (gameOne === 6 || gameTwo === 6)) {
      setGameTwoOne(gameTwoOne + 1);
    }

    if (gameTwoOne === 6 || gameTwoTwo === 6) {
      setGameTwoOne(gameTwoOne);
      setGameTwoTwo(gameTwoTwo);
    }

    // ThirdInput Counting
    if (gameThreeOne < 6 && (gameTwoOne === 6 || gameTwoTwo === 6)) {
      setGameThreeOne(gameThreeOne + 1);
    }

    if (gameThreeOne === 6 || gameThreeTwo === 6) {
      setGameThreeOne(gameThreeOne);
      setGameThreeTwo(gameThreeTwo);
    }

    // Fourth Input Counting
    if (gameFoureOne < 6 && (gameThreeOne === 6 || gameThreeTwo === 6)) {
      setGameFoureOne(gameFoureOne + 1);
    }

    if (gameFoureOne === 6 || gameFoureTwo === 6) {
      setGameFoureOne(gameFoureOne);
      setGameFoureTwo(gameFoureTwo);
    }

    // Fifth Input Counting
    if (gameFiveOne < 6 && (gameFoureOne === 6 || gameFoureTwo === 6)) {
      setGameFiveOne(gameFiveOne + 1);
    }
  };

  const increaseScoreTwo = () => {
    // game One Score Increase
    if (gameTwo < 6) {
      setGameTwo(gameTwo + 1);
    }
    // Stop Increasing When Reached To Condition
    if (gameOne === 6 || gameTwo === 6) {
      setGameOne(gameOne);
      setGameTwo(gameTwo);
    }
    // Game Two Score Increasing
    if (gameTwoTwo < 6 && (gameOne === 6 || gameTwo === 6)) {
      setGameTwoTwo(gameTwoTwo + 1);
    }

    if (gameTwoOne === 6 || gameTwoTwo === 6) {
      setGameTwoOne(gameTwoOne);
      setGameTwoTwo(gameTwoTwo);
    }
    // Game Three Score Increase
    if (gameThreeTwo < 6 && (gameTwoOne === 6 || gameTwoTwo === 6)) {
      setGameThreeTwo(gameThreeTwo + 1);
    }

    if (gameThreeOne === 6 || gameThreeTwo === 6) {
      setGameThreeOne(gameThreeOne);
      setGameThreeTwo(gameThreeTwo);
    }

    // Fourth Input Counting
    if (gameFoureTwo < 6 && (gameThreeOne === 6 || gameThreeTwo === 6)) {
      setGameFoureTwo(gameFoureTwo + 1);
    }

    if (gameFoureOne === 6 || gameFoureTwo === 6) {
      setGameFoureOne(gameFoureOne);
      setGameFoureTwo(gameFoureTwo);
    }

    // Fifth Input Counting
    if (gameFiveTwo < 6 && (gameFoureOne === 6 || gameFoureTwo === 6)) {
      setGameFiveTwo(gameFiveTwo + 1);
    }
  };

  // Game one Disable
  if (gameOne === 6 || gameTwo === 6) {
    document.getElementById("gameone").disabled = true;
    document.getElementById("gameTwo").disabled = true;
    document.getElementById("gameone").style.backgroundColor = "lightgrey";
    document.getElementById("gameTwo").style.backgroundColor = "lightgrey";
  }
  // Game Two Disable
  if (gameTwoOne === 6 || gameTwoTwo === 6) {
    document.getElementById("gameTwoOne").disabled = true;
    document.getElementById("gameTwoTwo").disabled = true;
    document.getElementById("gameTwoOne").style.backgroundColor = "lightgrey";
    document.getElementById("gameTwoTwo").style.backgroundColor = "lightgrey";
  }
  // Game three Disable
  if (gameThreeOne === 6 || gameThreeTwo === 6) {
    document.getElementById("gameThreeOne").disabled = true;
    document.getElementById("gameThreeTwo").disabled = true;
    document.getElementById("gameThreeOne").style.backgroundColor = "lightgrey";
    document.getElementById("gameThreeTwo").style.backgroundColor = "lightgrey";
  }
  // Game Foure Disable
  if (gameFoureOne === 6 || gameFoureTwo === 6) {
    document.getElementById("gameFoureOne").disabled = true;
    document.getElementById("gameFoureTwo").disabled = true;
    document.getElementById("gameFoureOne").style.backgroundColor = "lightgrey";
    document.getElementById("gameFoureTwo").style.backgroundColor = "lightgrey";
  }
  // Game Five Disable
  // if (gameFiveOne === 6 || gameFiveTwo === 6) {
  //   document.getElementById("gameFiveOne").disabled = true;
  //   document.getElementById("gameFiveTwo").disabled = true;
  //   document.getElementById("gameFiveOne").style.backgroundColor = "lightgrey";
  //   document.getElementById("gameFiveTwo").style.backgroundColor = "lightgrey";
  // }
  const DecreaseScoreOne = () => {
    // Decrease Game 1 Score
    if (gameOne > 0) {
      setGameOne(gameOne - 1);
    }
    if (gameOne === 6 || gameTwo === 6) {
      setGameOne(gameOne);
      setGameTwo(gameTwo);
    }
    // Decrease Game 2 Score
    if (gameTwoOne > 0 && (gameOne === 6 || gameTwo === 6)) {
      setGameTwoOne(gameTwoOne - 1);
    }
    if (gameTwoOne === 6 || gameTwoTwo === 6) {
      setGameTwoOne(gameTwoOne);
      setGameTwoTwo(gameTwoTwo);
    }
    // Decrease Game 3 Score
    if (gameThreeOne > 0 && (gameTwoOne === 6 || gameTwoTwo === 6)) {
      setGameThreeOne(gameThreeOne - 1);
    }
    if (gameThreeOne === 6 || gameThreeTwo === 6) {
      setGameThreeOne(gameThreeOne);
      setGameTwoTwo(gameThreeTwo);
    }
    // Decrease Game 4 Score
    if (gameFoureOne > 0 && (gameThreeOne === 6 || gameThreeTwo === 6)) {
      setGameFoureOne(gameFoureOne - 1);
    }
    if (gameThreeOne === 6 || gameThreeTwo === 6) {
      setGameThreeOne(gameThreeOne);
      setGameTwoTwo(gameThreeTwo);
    }
    // Decrease Game 5 Score
    if (gameFiveOne > 0 && (gameFoureOne === 6 || gameFoureTwo === 6)) {
      setGameFiveOne(gameFiveOne - 1);
    }
    if (gameFoureOne === 6 || gameFoureTwo === 6) {
      setGameFoureOne(gameFoureOne);
      setGameFiveTwo(gameFoureTwo);
    }
  };

  const DecreaseScoreTwo = () => {
    // Decrease Game 1 Score
    if (gameTwo > 0) {
      setGameTwo(gameTwo - 1);
    }
    if (gameOne === 6 || gameTwo === 6) {
      setGameOne(gameOne);
      setGameTwo(gameTwo);
    }
    // Decrease Game 2 Score
    if (gameTwoTwo > 0 && (gameOne === 6 || gameTwo === 6)) {
      setGameTwoTwo(gameTwoTwo - 1);
    }
    if (gameTwoOne === 6 || gameTwoTwo === 6) {
      setGameTwoOne(gameTwoOne);
      setGameTwoTwo(gameTwoTwo);
    }
    // Decrease Game 3 Score
    if (gameThreeTwo > 0 && (gameTwoOne === 6 || gameTwoTwo === 6)) {
      setGameThreeTwo(gameThreeTwo - 1);
    }
    if (gameThreeOne === 6 || gameThreeTwo === 6) {
      setGameThreeOne(gameThreeOne);
      setGameThreeTwo(gameThreeTwo);
    }
    // Decrease Game 4 Score
    if (gameFoureTwo > 0 && (gameThreeOne === 6 || gameThreeTwo === 6)) {
      setGameFoureTwo(gameFoureTwo - 1);
    }
    if (gameThreeOne === 6 || gameThreeTwo === 6) {
      setGameThreeOne(gameThreeOne);
      setGameThreeTwo(gameThreeTwo);
    }
    // Decrease Game 5 Score
    if (gameFiveTwo > 0 && (gameFoureOne === 6 || gameFoureTwo === 6)) {
      setGameFiveTwo(gameFiveTwo - 1);
    }
    if (gameFoureOne === 6 || gameFoureTwo === 6) {
      setGameFoureOne(gameFoureOne);
      setGameFoureTwo(gameFoureTwo);
    }
  };

  let Player1 =
    gameOne + gameTwoOne + gameThreeOne + gameFoureOne + gameFiveOne;
  let Player2 =
    gameTwo + gameTwoTwo + gameThreeTwo + gameFoureTwo + gameFiveTwo;

  if ((gameFiveOne === 6 || gameFiveTwo === 6) && Player1 > Player2) {
    open = true;
  }

  if ((gameFiveOne === 6 || gameFiveTwo === 6) && Player2 > Player1) {
    alert("Player 2 Won The Match");
  }

  return (
    <>
      <div className="MatchDetails_pr">
        <div className="MatchDetails_ch">
          <div className="MatchDetails_ch_headerPoster">
            <img src={MatchBanner} alt="" />
          </div>
          <div className="MatchDetails_ch_category">
            <h4>
              Match Category: <span>{State.state.match_name} </span>
            </h4>
          </div>
          <div className="MatchDetails_ch_teams MatchDetails_ch_teams-card ">
            <div className="MatchDetails_ch_timer">
              <div className="MatchDetails_teamcode_wrappper">
                <div className="MatchDetails_teamcode">
                  Match Location : &nbsp;{" "}
                  <span>{State.state.match_location}</span>
                </div>
                <div className="MatchDetails_teamcode">
                  Umpire Name : &nbsp; <span>{State.state.umpire_name}</span>
                </div>
              </div>
            </div>
            <div className="MatchDetails_ch_teamsScore">
              <div className="MatchDetails_ch_Team1">
                <div className="MatchDetails_ch_TeamSection">
                  <div className="MatchDetails_ch_TeamSection_img">
                    <img src={team1} alt="" />
                  </div>

                  <div className="MatchDetails_ch_TeamSection_teamname">
                    <h4>{State.state.team_one_name}</h4>
                  </div>

                  <div className="matchDetail_playerNames_pr">
                    <div className="matchDetail_playerNames">
                      <div className="matchDetail_playerImage"></div>
                      {State.state.player_name_one}
                    </div>
                    <div className="matchDetail_playerNames">
                      <div className="matchDetail_playerImage"></div>
                      {State.state.player_name_two}
                    </div>
                  </div>

                  <div className="MatchDetails_ch_TeamSection_teamScore">
                    <button className="score_increase" onClick={increaseScore}>
                      <AddIcon />
                    </button>

                    <input
                      type="text"
                      value={gameOne}
                      name="gameOne"
                      id="gameone"
                    />

                    <input type="text" value={gameTwoOne} id="gameTwoOne" />
                    <input type="text" value={gameThreeOne} id="gameThreeOne" />
                    <input type="text" value={gameFoureOne} id="gameFoureOne" />
                    <input type="text" value={gameFiveOne} id="gameFoureOne" />
                    <button
                      className="score_increase"
                      onClick={DecreaseScoreOne}
                    >
                      <ReplayIcon />
                    </button>
                  </div>
                </div>
              </div>
              <div className="MatchDetails_ch_Team1">
                <div className="MatchDetails_ch_TeamSection">
                  <div className="MatchDetails_ch_TeamSection_img">
                    <img src={team1} alt="" />
                  </div>

                  <div className="MatchDetails_ch_TeamSection_teamname">
                    <h4>{State.state.team_two_name}</h4>
                  </div>
                  <div className="matchDetail_playerNames_pr">
                    <div className="matchDetail_playerNames">
                      <div className="matchDetail_playerImage"></div>
                      {State.state.teamTwo_player_name_one}
                    </div>
                    <div className="matchDetail_playerNames">
                      <div className="matchDetail_playerImage"></div>
                      {State.state.teamTwo_player_name_two}
                    </div>
                  </div>

                  <div className="MatchDetails_ch_TeamSection_teamScore">
                    <button
                      className="score_increase"
                      onClick={increaseScoreTwo}
                    >
                      <AddIcon />
                    </button>
                    <input type="text" value={gameTwo} id="gameTwo" />
                    <input type="text" value={gameTwoTwo} id="gameTwoTwo" />
                    <input type="text" value={gameThreeTwo} id="gameThreeTwo" />
                    <input type="text" value={gameFoureTwo} id="gameFoureTwo" />
                    <input type="text" value={gameFiveTwo} id="gameFoureTwo" />

                    <button
                      className="score_increase"
                      onClick={DecreaseScoreTwo}
                    >
                      <ReplayIcon />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="Dialog_Winner">
          <img src={Winnerlogo} alt="" />
          <div>
            <h3>Player 1 Won The Match</h3>
          </div>
          <div className="dia_button">
            <Link to="/">
              <button>Leave</button>
            </Link>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default MatchDetails;
