import * as React from "react";
import Paper from "@mui/material/Paper";
import './EditScore.css'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useEffect } from "react";
import axios from "axios";
import axiosBaseURL from "../../../httpCommon";
import { useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import EditScore from "./EditScore";
// 
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
// Loder
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function index() {

  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);
    const [open, setOpen] = React.useState(false);
    const [editedData,setEditedData]=useState([])






  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [mapData, setMApData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const token = localStorage.getItem("tokenTennis");

const getData = ()=>{

  axiosBaseURL
  .get("/current-match-list", {
    headers: { Authorization: `Bearer ${token}` },
  })
  .then((resp) => {
    // alert(resp.data.message);
    setMApData(resp.data.data);
  })
  .catch((err) => {
    console.log(err);
  });
}

useEffect(()=>{
  getData()
},[])

  const handleClose = (id) => {


    console.log(id.team_id,"hhhhhhh")

    const bearerToken = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      axiosBaseURL.post(`/score-update-admin/${id.id}`, editedData, bearerToken)
        .then((resp) => {
          // alert(resp.data.message);
          console.log(resp, "Daaataaata");
          getData()
        })
        .catch((err) => {
          console.log(err);
        });
    
  setOpen(false);
};
  const EditHandler=(id)=>{
    setEditedData(id)
    setOpen(true);
  }

  const changeHandler = (e) => {
    const { value, name } = e.target;
    setEditedData((oldValue) => {
      return {
        ...oldValue,
        [name]: value,
      };
    });
  };

  return (
    <>
    <div className="ScoreTable">
    <Paper sx={{ width: "100%",  overflow: "hidden" }}>
      <h3>Update Match Score</h3>

      {
        (mapData.length===0)?
        
        <div className="nodataScore">
           <h4>No Data Found</h4>
        </div>
        
        // <CircularProgressWithLabel value={progress} />
        :
        <TableContainer sx={{ maxHeight: 800}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 170 }}>Match name</TableCell>
              <TableCell style={{ minWidth: 170 }}>Team name</TableCell>
              <TableCell style={{ minWidth: 170 }}>Game Score 1</TableCell>
              <TableCell style={{ minWidth: 170 }}>Game Score 2</TableCell>
              <TableCell style={{ minWidth: 170 }}>Game Score 3</TableCell>
              <TableCell style={{ minWidth: 170 }}>Game Score 4</TableCell>
              <TableCell style={{ minWidth: 170 }}>Game Score 5</TableCell>
              <TableCell style={{ minWidth: 170 }}>Edit Score</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mapData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <>
                    <TableRow>
                      <TableCell>{row.match_name}</TableCell>
                      <TableCell>{row.team_name}</TableCell>
                      <TableCell>{row.score_one}</TableCell>
                      <TableCell>{row.score_two}</TableCell>
                      <TableCell>{row.score_three}</TableCell>
                      <TableCell>{row.score_four}</TableCell>
                      <TableCell>{row.score_five}</TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={2}>
                          <Button variant="outlined" onClick={()=>EditHandler(row)}>Edit</Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      }
    
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={mapData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </div>

        <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
            <div className="EditScoreContainer">
            <form action="">
                <div className="Form_cont">
                <label htmlFor="Team Name"> Match Name : </label>
                <input type="text" placeholder="Match name" value={editedData.match_name} name="match_name" onChange={changeHandler} />
                </div>
          
                <div className="Form_cont">
                <label htmlFor="Team Name"> Team Name : </label><input type="text" placeholder="Team Name" value={editedData.team_name} onChange={changeHandler} name="team_name"/>
                </div>

                <div className="Form_cont">
                <label htmlFor="Team Name"> Game Score 1 : </label><input type="text" placeholder="Game Score 1 "  value={editedData.score_one} name="score_one" onChange={changeHandler}/>
                </div>

                <div className="Form_cont">
                <label htmlFor="Team Name"> Game Score 2 : </label><input type="text" placeholder="Game Score 2" value={editedData.score_two} name="score_two" onChange={changeHandler}/>
                </div>

                <div className="Form_cont">
                <label htmlFor="Team Name"> Game Score 3 : </label><input type="text" placeholder="Game Score 3"  value={editedData.score_three} name="score_three" onChange={changeHandler}/>
                </div>

                <div className="Form_cont">
                <label htmlFor="Team Name"> Game Score 4 : </label><input type="text" placeholder="Game Score 4"  value={editedData.score_four} name="score_four" onChange={changeHandler}/>
                </div>

                <div className="Form_cont">
                <label htmlFor="Team Name"> Game Score 5 : </label><input type="text" placeholder="Game Score 5"  value={editedData.score_five} name="score_five" onChange={changeHandler}/>
                </div>
            </form>
            </div>

          <DialogActions>
            <Button  className="updateSubmit" onClick={()=>handleClose(editedData)}>Submit</Button>
          </DialogActions>
        </Dialog>
  
      </div>
      </>
  );
}
