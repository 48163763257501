import React from "react";
import "./Home.css";
import { Component } from "react";
// import ReactCardCarousel from "react-card-carousel";
import player1 from "../assets/player1.jfif";
import player2 from "../assets/player2.jfif";
import { PlayerRanking } from "./PlayerRanking";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
// import Carousel from 'react-bootstrap/Carousel';
import img1 from "../assets/images/slider-tennis-31.jpg";
import axiosBaseURL from "../httpCommon";
import notefoundImage from "../../src/assets/icons/notfoundimage.png";
import TennisVideo from "../../src/assets/video/TennisScoringVideo.mp4";
import Carousel from 'react-bootstrap/Carousel';

const Home = () => {
  const ContainerStyle = {
    position: "relative",
    height: "100%",
    width: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "middle",
  };

  const [data, setData] = useState([]);

  const bearerToken = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  useEffect(() => {
    axiosBaseURL
      .get("/upcomming-match", bearerToken)
      .then((resp) => {
        // alert(resp.data.message);
        console.log(resp.data.data, "Daaataaata");
        setData(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="tennis_home">
      {/* <Carousel className="home_carousel">
      <Carousel.Item className="home_carouselItem"> */}
      <div className="homeCard_carousel1">
        <video width="100%" height="100%" autoPlay muted loop playsInline >
          <source src={TennisVideo} type="video/mp4" id="yyyy"/>
          </video>
        <div style={ContainerStyle} className="Upcommingmatch_Carousel">
          {data.length === 0 ? (
            // <ReactCardCarousel autoplay={true} autoplay_speed={7000}>
            <Carousel  className="card_carousel">
              <Carousel.Item className="card_carousel_item"> 
            <div className="card_carousel_hpage">
              <div className="nexr_mtchTour">
                <h6>Match Name : No Data</h6>
                <h5>Next Match</h5>
              </div>
              <div className="player_Wrapper">
                <div className="Player__">
                  <img src={notefoundImage} alt="" className="player_img" />
                  <p className="player_name">No Data</p>
                </div>
                <div className="vs">Vs</div>
                <div className="Player__">
                  <img src={notefoundImage} alt="" className="player_img" />
                  <p className="player_name">No Data</p>
                </div>
              </div>
            </div>
            </Carousel.Item>
            </Carousel>
          ) : (
            // </ReactCardCarousel>
            // <ReactCardCarousel autoplay={true} autoplay_speed={7000}>
            <>
             <Carousel  className="card_carousel">
              {data.map((currValue, index) => {
                return (
                  <Carousel.Item className="card_carousel_item"> 
                  <div className="card_carousel_hpage" key={index}>
                    <div className="nexr_mtchTour">
                      <h6>Match Name : {currValue.match_name}</h6>
                      <h5>Next Match</h5>
                    </div>
                    <div className="player_Wrapper">
                      <div className="Player__">
                        <img src={player1} alt="" className="player_img" />
                        <p className="player_name">{currValue.team_one.team_name}</p>
                      </div>
                      <div className="vs">Vs</div>
                      <div className="Player__">
                        <img src={player2} alt="" className="player_img" />
                        <p className="player_name">{currValue.team_two.team_name}</p>
                      </div>
                    </div>
                  </div>
                    </Carousel.Item>
                     );
                    })}
               </Carousel>

              {/* </ReactCardCarousel> */}
            </>
          )}
        </div>

      </div>

  
      {/* </Carousel.Item> */}
      {/* <Carousel.Item> */}
      {/* <div className="homeCard_carousel_2"></div> */}
      {/* </Carousel.Item> */}
      {/* <Carousel.Item> */}
      {/* <div className="homeCard_carousel_3"></div> */}
      {/* </Carousel.Item> */}
      {/* </Carousel> */}
    </div>
  );
};
export default Home;
