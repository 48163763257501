import React, { useState } from "react";
import "./CreateMatch.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import axiosBaseURL from "../../../httpCommon";

// Mui Date
const index = () => {
  const [team1, setTeam1] = useState(false);
  const [team2, setTeam2] = useState(false);
  const [player1Selection, setplayerSelection1] = useState(false);
  const [player2Selection, setplayerSelection2] = useState(false);
  const [court, setCourt] = useState(false);
  const [umpireSelection, setUmpireSelection] = useState(false);
  const [matchCatDrop,setMatchCatDrop] = useState(false);

  const handleMatchDropClose = () => {
    setMatchCatDrop(false)
  }
  const handleMatchDropOpen = () => {
    setMatchCatDrop(true)
  }

  const handleTeam1Close = () => {
    setTeam1(false);
  };
  const handleteam2Open = () => {
    setTeam1(true);
  };
  const handleplayer1SelectionClose = () => {
    setplayerSelection1(false);
  };
  const handleplayer1SelectionOpen = () => {
    setplayerSelection1(true);
  };
  const handleplayer2Open = () => {
    setTeam2(true);
  };
  const handleplayer2Close = () => {
    setTeam2(false);
  };
  const handleplayer2SelectionClose = () => {
    setplayerSelection2(false);
  };
  const handleplayer2SelectionOpen = () => {
    setplayerSelection2(true);
  };
  const handlCourtClose = () => {
    setCourt(false);
  };
  const handleCourtOpen = () => {
    setCourt(true);
  };
  const handleumpireSelectionClose = () => {
    setUmpireSelection(false);
  };
  const handleumpireSelectionOpen = () => {
    setUmpireSelection(true);
  };

  const [match, setMatch] = useState({
    match_name: "",
    match_category_id: "",
    team_one_id: "",
    // player_one_id: "122",
    court_id: "",
    match_date: "",
    match_time: "",
    team_two_id: "",
    // player_two_id: "123",
    umpire_id: "",
    match_location: "",
  });


  
  const [cat, setcat] = useState([]);
  const [court1, setcourt1] = useState([]);




  const chnageHandler = (e) => {
    const { value, name } = e.target;
    setMatch((oldvalue) => {
      return {
        ...oldvalue,
        [name]: value,
      };
    });
  };
  const handleSubmit = () => {
    // setMatchData([...matchData, match]);


    const token = localStorage.getItem("tokenTennis");
    
    const bearerToken = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axiosBaseURL.post('/matches', match, bearerToken)
      .then((resp) => {
        // alert(resp.data.message);
        console.log(resp, "Daaataaata");
      })
      .catch((err) => {
        console.log(err);
      });

      
  };

  const [teamName,setTeamName] = useState([])
  
  console.log(court1,"cat")

  const token = localStorage.getItem("tokenTennis");

  useEffect(() => {

    axiosBaseURL
      .get('/teams', { headers: { Authorization: `Bearer ${token}` } })
      .then((resp) => {
        // alert(resp.data.message);
        setTeamName(resp.data.data)
      })
      .catch((err) => {
        console.log(err);
      });

    axiosBaseURL.get('/match_category', { headers: { Authorization: `Bearer ${token}` } })
      .then((resp) => {
        // alert(resp.data.message);
        setcat(resp.data.data)
      })
      .catch((err) => {
        console.log(err);
      });

    axiosBaseURL
      .get('/court_type', { headers: { Authorization: `Bearer ${token}` } })
      .then((resp) => {
        // alert(resp.data.message);
        setcourt1(resp.data.data)
      })
      .catch((err) => {
        console.log(err);
      });

      
  }, []);

  
  const [umpireNameDrop,setUmpireNameDrop]=useState([])

  useEffect(() => {
    axiosBaseURL
      .get('/umpires', { headers: { Authorization: `Bearer ${token}` } })
      .then((resp) => {
        // alert(resp.data.message);
        console.log(resp.data.data, "123445");
        setUmpireNameDrop(resp.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="createMatch_">
      <div className="createMatch_header">
        <h3>Create Match</h3>
        <button className="creat_matchbtn">Create New Match</button>
      </div>
      <div className="createMatch_fields">
        <Box
          sx={{
            width: "95%",
            maxWidth: "100%",
            padding: "15px 0",
          }}
        >
          <label>Match Name</label>
          <TextField
            fullWidth
            label=""
            id="fullWidth"
            placeholder="  --  Select  --  "
            name="match_name"
            value={match.match_name}
            onChange={chnageHandler}
          />
        </Box>
        {/* <Box
          sx={{
            width: "95%",
            maxWidth: "100%",
            padding: "15px 0",
          }}
        >
          <label>Match Category</label>
          <TextField
            fullWidth
            label=""
            id="fullWidth"
            placeholder="  --  Select  --  "
            name="match_category_id"
            value={match.match_category_id}
            onChange={chnageHandler}
          />
        </Box> */}
            <FormControl       sx={{
            width: "95%",
            maxWidth: "100%",
            padding: "5px 0",
          }}>
              <InputLabel id="demo-multiple-checkbox-label">
              Match Category
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={matchCatDrop}
                onClose={handleMatchDropClose}
                onOpen={handleMatchDropOpen}
                label="Match Category"
                name="match_category_id"
                value={match.match_category_id}
                onChange={chnageHandler}
              >

                {
                  cat.map((e,index)=>{
                    return (
                      <MenuItem key={index} value={e.id}>{e.match_category_name}</MenuItem>
                    )
                  })
                }
              
              </Select>
            </FormControl>
      </div>
      <div className="match_createMatch_fields_ch">
        <div className="match_createMatch_fields_ph">
          <div className="match_createMatch_fields_ch_aside_right">
            <FormControl sx={{ m: 1, minWidth: "90%" }}>
              <InputLabel id="demo-multiple-checkbox-label">
                Team Name
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={team1}
                onClose={handleTeam1Close}
                onOpen={handleteam2Open}
                label="Team Names"
                name="team_one_id"
                value={match.team_one_id}
                onChange={chnageHandler}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
              {
                teamName.map((map,index)=>{
                  return (
                    <MenuItem key={index} value={map.id}> {map.team_name}</MenuItem>
                  )
                })
              }
              </Select>

            </FormControl>
            
{/* 
            <FormControl sx={{ m: 1, minWidth: "45%" }}>
              <InputLabel id="demo-multiple-checkbox-label">
                Player Selection
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={player1Selection}
                onClose={handleplayer1SelectionClose}
                onOpen={handleplayer1SelectionOpen}
                label="Player Selection"
                name="player_one_id"
                value={match.player_one_id}
                onChange={chnageHandler}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Adel NourEgypt</MenuItem>
                <MenuItem value={2}>Arnaud Gabas</MenuItem>
                <MenuItem value={30}>Carlos Bernardes</MenuItem>
                <MenuItem value={40}>Damien Dumusois</MenuItem>
                <MenuItem value={50}>Emmanuel Joseph</MenuItem>
              </Select>
            </FormControl> */}

            <FormControl sx={{ m: 1, width: "90%" }}>
              <InputLabel id="demo-multiple-checkbox-label">Court</InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={court}
                onClose={handlCourtClose}
                onOpen={handleCourtOpen}
                label="Select Umpire"
                name="court_id"
                value={match.court_id}
                onChange={chnageHandler}
              >
               {
                  court1.map((e,index)=>{
                    return (
                      <MenuItem key={index} value={e.id}>{e.court_type_name}</MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, width: "91.7%" }}>

              <input
                type="date"
                className="creatematchDate"
                name="match_date"
                value={match.match_date}
                onChange={chnageHandler}
              />
            </FormControl>
            <FormControl sx={{ m: 1, width: "90%" }}>
              <TextField
                id="outlined-basic"
                label="  Match Location"
                variant="outlined"
                name="match_location"
                value={match.match_location}
                onChange={chnageHandler}
              />
            </FormControl>
          </div>

          <div className="match_createMatch_fields_ch_aside_left">
            <FormControl sx={{ m: 1, minWidth: "90%" }}>
              <InputLabel id="demo-multiple-checkbox-label">
                Team Name
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={team2}
                onClose={handleplayer2Close}
                onOpen={handleplayer2Open}
                label="Team Name"
                name="team_two_id"
                value={match.team_two_id}
                onChange={chnageHandler}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {
                teamName.map((map,index)=>{
                  return (
                    <MenuItem key={index} value={map.id}>{map.team_name}</MenuItem>
                  )
                })
              }
              </Select>
            </FormControl>

            {/* <FormControl sx={{ m: 1, minWidth: "45%" }}>
              <InputLabel id="demo-multiple-checkbox-label">
                Player Selection
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={player2Selection}
                onClose={handleplayer2SelectionClose}
                onOpen={handleplayer2SelectionOpen}
                label="Player Selection"
                name="player_two_id"
                value={match.player_two_id}
                onChange={chnageHandler}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1}>Adel NourEgypt</MenuItem>
                <MenuItem value={2}>Arnaud Gabas</MenuItem>
                <MenuItem value={3}>Carlos Bernardes</MenuItem>
                <MenuItem value={4}>Damien Dumusois</MenuItem>
                <MenuItem value={5}>Emmanuel Joseph</MenuItem>
              </Select>
            </FormControl> */}
            
            <FormControl sx={{ m: 1, width: "90%" }}>
              <InputLabel id="demo-multiple-checkbox-label">
                Select Umpire
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={umpireSelection}
                onClose={handleumpireSelectionClose}
                onOpen={handleumpireSelectionOpen}
                label="Select Umpire"
                name="umpire_id"
                value={match.umpire_id}
                onChange={chnageHandler}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {
                umpireNameDrop.map((map,index)=>{
                  return (
                    <MenuItem key={index} value={map.id}>{map.umpire_name}</MenuItem>
                  )
                })
              }
              </Select>
            </FormControl>
            
            <FormControl sx={{ m: 1, width: "90%" }}>
              <input
                type="time"
                className="creatematchDate"
                name="match_time"
                value={match.match_time}
                onChange={chnageHandler}
              />
            </FormControl>
          </div>
        </div>
      </div>
      <div className="create_match_btns">
      <Link to="/admindash/matcheslisting">
        <button className="crt_match_save" onClick={handleSubmit}>
          SAVE
        </button>
        </Link>
  
        <button className="crt_match_cancle">CANCLE</button>
      </div>
    </div>
  );
};

export default index;
