import React, { useState } from "react";
import "./umpair.css";
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
// import Fault from '../../Components/fault'
// import ReturnError from '../../Components/returnerror'
// import ReturnWinner  from '../../Components/ReturnWinner'
// import UnforcedError1 from '../../Components/UnforcedError1'
// import ForcerdError1 from '../../Components/ForcedError1'
// import UnforcedError2 from '../../Components/ForcedError2'
// import ForcerdError2 from '../../Components/ForcedError2'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const rules = {
  fault:{value:1},
  returnerror:{value:2},
  returnwinner:{value:3},
  unforcedError1:{value:4},
  forcerdError1:{value:5},
  unforcedError2:{value:6},
  forcerdError2:{value:7},
}

const index = () => {
  const [serve, setserve] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [playingPoint,setPlayingPoint] = useState(false)
  const [rulepopUp,setrulepopUp] = useState(rules.fault.value)

  const handleClickOpen = (rule) => {
    console.log(rule)
      setOpen(true);
      if(rule === 1){
        setrulepopUp(rules.fault.value)
      }
      if(rule === 2){
        setrulepopUp(rules.returnerror.value)
      }
      if(rule === 3){
        setrulepopUp(rules.returnwinner.value)
      }
      if(rule === 4){
        setrulepopUp(rules.unforcedError1.value)
    }   
      if(rule === 5){
        setrulepopUp(rules.forcerdError1.value)
    }  
      if(rule === 6){
        setrulepopUp(rules.unforcedError2.value)
    }  
      if(rule === 7){
        setrulepopUp(rules.forcerdError2.value)
    }  
  };

  const handleClose = () => {
      setOpen(false);
  };
  const serveHandler = () => {
    if (serve === false) {
      setserve(true);
    } else {
      setserve(false);
    }
  };
  const PlayingPointHandler = () =>{
    setPlayingPoint(true)
  }
  return (
    <div className="umpairApp">
      <div className="inner_umpair">
        <div className="team_name_score">
          <div className="first_team_name">
            <div className="serve_team">
              {serve ? <div className="serve"></div> : null}
            </div>
            <div className="_teams">
              <div className="_team_name_">
                <span>Sekiguchi S.</span>
              </div>
              <div className="teamScore">
                <div className="game_point">
                  <span>0</span>
                </div>
                <div className="set_point">
                  <span>0</span>
                </div>
              </div>
            </div>
          </div>
          <div className="sec_team_name">
            <div className="serve_team">
              {serve ? null : <div className="serve"></div>}
            </div>
            <div className="_teams">
              <div className="_team_name_">
                <span>Sekiguchi S.</span>
              </div>
              <div className="teamScore">
                <div className="game_point">
                  <span>0</span>
                </div>
                <div className="set_point">
                  <span>0</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="switch_serve">
          <button
            onClick={serveHandler}
            style={{ backgroundColor: "white", color: "black" }}
          >
            Switch :
          </button>
          {serve ? (
            <button
              onClick={serveHandler}
              style={{ backgroundColor: "#6ebd47", color: "white" }}
            >
              Team1
            </button>
          ) : (
            <button onClick={serveHandler}>Team1</button>
          )}

          {serve ? (
            <button onClick={serveHandler}>Team2</button>
          ) : (
            <button
              onClick={serveHandler}
              style={{ backgroundColor: "#6ebd47", color: "white" }}
            >
              Team2
            </button>
          )}
        </div>
        {
          playingPoint ? 
          <>
          <div className="fault">
          <button className="ace_btn">Net</button>
          <button className="ser_win_btn">Net</button>
        </div>
          <div className="fault">
          <button className="ace_btn">Winner</button>
          <button className="ser_win_btn">Winner</button>
        </div>
          <div className="fault">
          <button className="ace_btn" onClick={()=>{handleClickOpen(4)}}>Unforced Error</button>
          <button className="ser_win_btn" onClick={()=>{handleClickOpen(6)}}>Unforced Error</button>
        </div>
          <div className="fault">
          <button className="ace_btn" onClick={()=>{handleClickOpen(5)}}>forced Error</button>
          <button className="ser_win_btn" onClick={()=>{handleClickOpen(7)}}>forced Error</button>
        </div>
        </>
        :
        <>
         <div className="fault">
          <button className="fault_btn" onClick={()=>{handleClickOpen(1)}}>Fault</button>
        </div>
        <div className="fault">
          <button className="ace_btn">Ace</button>
          <button className="ser_win_btn">Service Winner</button>
        </div>
        <div className="fault">
          <button className="ace_btn" onClick={()=>{handleClickOpen(2)}}>Return Error</button>
          <button className="ser_win_btn" onClick={()=>{handleClickOpen(3)}}>Return Winner</button>
        </div>
        <div className="fault">
          <button className="fault_btn" onClick={PlayingPointHandler}>Playing point</button>
        </div>
        </>
        }
         <div className="undo_stats">
          <button className="undo">Undo</button>
          <button className="stats">Stats</button>
        </div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          className="login_dialog"
        >
          <div className="Log_head">
            <div className="score_rule">
              {
                rulepopUp === 1?<Fault/>:null
              }
              {
                rulepopUp === 2?<ReturnError/>:null
              }
              {
                rulepopUp === 3?<ReturnWinner/>:null
              }
              {
                rulepopUp === 4?<UnforcedError1/>:null
              }
              {
                rulepopUp === 5 ?<ForcerdError1/>:null
              }
              {
                rulepopUp === 6 ?<UnforcedError2/>:null
              }
              {
                rulepopUp === 7 ?<ForcerdError2/>:null
              }

  

            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default index;
