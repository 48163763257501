import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: false,
  }
  
  export const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
      headerTrue: (state) => {
        state.value = true;
      },
      headerFalse: (state) => {
        state.value = false;
      },
    },
  })
  
  export const { headerTrue, headerFalse} = headerSlice.actions
  
  export default headerSlice.reducer